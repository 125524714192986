import React from "react";
import { unload, update } from "../redux/BusinessSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import BusinessController from "../repositories/BusinessController";
import { IBusiness } from "../types/IBusiness";
import { IPaginationMeta } from "../types/IPaginationMeta";

type UseBusinessesResponse = {
  businesses: IBusiness[];
  pagination?: IPaginationMeta;
  isLoading: boolean;
  selectBusiness: (selectedId: string) => void;
  reload: (limit: number, page: number) => Promise<void>;
};

export default function useBusinesses(): UseBusinessesResponse {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [businesses, setBusinesses] = React.useState<IBusiness[]>([]);
  const [pagination, setPagination] = React.useState<IPaginationMeta>();
  const user = useAppSelector((state) => state.auth.user);
  const selectedBusiness = useAppSelector((state) => state.business.business);
  const dispatch = useAppDispatch();

  const reload = React.useCallback(
    async (limit: number, page: number) => {
      setIsLoading(true);
      if (user) {
        try {
          // Check for businesses belonging to this user
          const result = await BusinessController.list({
            limit,
            page
          });

          if (result && result.status === "success") {
            setBusinesses(result.data);
            setPagination(result.meta);

            if (result.data.length > 0 && !selectedBusiness) {
              dispatch(update(result.data[0]));
            }
          }
        } catch (e) {
          setBusinesses([]);
          setPagination(undefined);
          dispatch(unload());
        }
      } else {
        dispatch(unload());
        setBusinesses([]);
        setPagination(undefined);
      }
      setIsLoading(false);
    },
    [user, selectedBusiness]
  );

  const selectBusiness = React.useCallback(
    (selectedId: string) => {
      const found = businesses.find((b) => b.uuid === selectedId);
      if (found) {
        dispatch(update(found));
      }
    },
    [businesses]
  );

  return React.useMemo<UseBusinessesResponse>(
    () => ({
      businesses,
      isLoading,
      pagination,
      reload,
      selectBusiness
    }),
    [businesses, isLoading, user, pagination, reload, selectBusiness]
  );
}
