import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from "react-router-dom";

const LinkRouterButton = React.forwardRef<never, Omit<RouterLinkProps, "to">>(
  function CustomLink(props, ref) {
    return <RouterLink ref={ref} to="/" {...props} role={undefined} />;
  }
);

export default LinkRouterButton;
