import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListProps,
  TextField,
  Typography,
  TypographyProps,
  styled
} from "@mui/material";
import React from "react";

type InvoiceTemplateEditorProps = {
  footer?: string;
  footnotes?: string[];
  setFooter: (f: React.SetStateAction<string>) => void;
  setFootnotes: (f: React.SetStateAction<string[]>) => void;
};

export default function InvoiceTemplateEditor({
  footer = "",
  footnotes = [],
  setFooter,
  setFootnotes
}: InvoiceTemplateEditorProps) {
  const [note, setNote] = React.useState<string>("");

  const handleAddFootnote = React.useCallback(() => {
    setFootnotes((prev) => prev.concat([note]));
    setNote(""); // Clear previous
  }, [note]);

  const removeItem = React.useCallback(
    (index: number) => {
      const _rows = [...footnotes];
      _rows.splice(index, 1);
      setFootnotes(_rows);
    },
    [footnotes]
  );

  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <TextField
          fullWidth
          id="inputFooter"
          label="Footer"
          value={footer}
          onChange={(e) => setFooter(e.target.value)}
          placeholder="Your company name, copyright 2024"
          helperText="Footer is shown at the bottom of your invoice."
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <TextField
          fullWidth
          id="inputFootnote"
          label="Add Footnote"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder="Payment details such as Bank Account can be shown here"
          helperText="Footnotes are shown at the bottom of your invoice, right before the footer. You can have multiple lines."
          InputProps={{
            endAdornment: (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleAddFootnote()}
              >
                Add
              </Button>
            )
          }}
        />
      </Box>
      {footnotes && (
        <Box sx={{ mb: 2 }}>
          <BoldTypography>Added Footnotes</BoldTypography>
          {footnotes && footnotes.length > 0 ? (
            <StyledList>
              {footnotes.map((note, index) => (
                <React.Fragment key={`note-${index}`}>
                  <ListItem
                    secondaryAction={
                      <IconButton
                        role="button"
                        onClick={() => removeItem(index)}
                      >
                        <DeleteForeverRoundedIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText>{note}</ListItemText>
                  </ListItem>
                </React.Fragment>
              ))}
            </StyledList>
          ) : (
            <Typography>No footnote added.</Typography>
          )}
        </Box>
      )}
    </Box>
  );
}

const BoldTypography = styled(Typography)<TypographyProps>({
  fontWeight: "700"
});

const StyledList = styled(List)<ListProps>(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
  "& li": {
    borderWidth: "0 0 2px 0",
    borderColor: theme.palette.action.hover,
    borderStyle: "solid"
  },
  "& li:last-of-type": {
    borderWidth: 0
  }
}));
