import { Alert, AlertTitle, SxProps, Theme, Typography } from "@mui/material";
import PaperForm from "./PaperForm";

type PaperErrorProps = {
  title: string;
  subtitle?: string;
  bodyTitle?: string;
  bodyContent?: string;
};

export default function PaperError({
  title,
  subtitle,
  bodyTitle,
  bodyContent,
}: PaperErrorProps) {
  return (
    <PaperForm>
      <Typography variant="h1" sx={titleSx}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="h4" sx={subtitleSx}>
          {subtitle}
        </Typography>
      )}
      {(bodyTitle || bodyContent) && (
        <Alert severity="error" sx={alertSx} variant="filled">
          {bodyTitle && bodyContent && <AlertTitle>{bodyTitle}</AlertTitle>}
          {bodyContent || bodyTitle}
        </Alert>
      )}
    </PaperForm>
  );
}

const titleSx: SxProps<Theme> = {
  textAlign: "center",
  fontWeight: 100,
};

const subtitleSx: SxProps<Theme> = {
  textAlign: "center",
  fontSize: 24,
  marginTop: "1rem",
};

const alertSx: SxProps<Theme> = {
  marginTop: "1rem",
};
