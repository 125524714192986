import { IBusiness } from "../types/IBusiness";
import { IBusinessRequestOptions } from "../types/IBusinessRequestOptions";
import { IHttpResponse } from "../types/IHttpResponse";
import { IPaginatedResponse } from "../types/IPaginatedResponse";
import HttpController from "./HttpController";

class BusinessController {
  /**
   * Represents a Business.
   * @constructor
   */
  constructor() {
    return this;
  }

  /**
   * Returns a list
   */
  static async list(
    options: IBusinessRequestOptions
  ): Promise<IPaginatedResponse<IBusiness>> {
    const searchParam = new URLSearchParams({
      limit: `${options.limit}`,
      page: `${options.page}`
    });

    const url = `/api/businesses?${searchParam.toString()}`;

    try {
      const result = await HttpController.get(url, false);
      return result;
    } catch (e) {
      console.error(e);
    }

    return {
      status: "error",
      data: []
    };
  }

  /**
   * Returns a business from its ID
   */
  static async get(businessId: string): Promise<IBusiness | null> {
    const url = `/api/businesses/${businessId}`;

    try {
      const result = await HttpController.get(url);
      return result;
    } catch (e) {
      console.error(e);
    }

    return null;
  }

  /**
   * Creates a single business
   */
  static async create(
    content: string
  ): Promise<IHttpResponse<IBusiness> | null> {
    const url = "/api/businesses";

    try {
      const result = await HttpController.post<IHttpResponse<IBusiness> | null>(
        url,
        content,
        false
      );
      return result;
    } catch (e) {
      console.error(e);
    }

    return null;
  }

  /**
   * Check if user has permission to create business
   */
  static async hasPermission(): Promise<IHttpResponse<string>> {
    const url = "/api/permissions/check/business/create";

    try {
      const result = await HttpController.get(url, false);
      return result;
    } catch (e) {
      console.error(e);
    }

    return {
      status: "error",
      message: "Unknown error with checking permission.",
      support: "Uknown error occured from server."
    };
  }
}

export default BusinessController;
