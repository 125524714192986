import { ThemeOptions } from "@mui/material";

const rdaDarkThemeOptions: ThemeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: "#414645"
    },
    secondary: {
      main: "#997c6c",
      light: "#dacdc6"
    },
    error: {
      main: "#a44a4a"
    },
    warning: {
      main: "#d67929"
    },
    info: {
      main: "#3b81a2"
    },
    success: {
      main: "#417943"
    }
  },
  typography: {
    fontFamily: [
      "Roboto",
      "'Helvetica Neue'",
      "Arial",
      "sans-serif",
      "-apple-system",
      "BlinkMacSystemFont",
      "'Segoe UI'",
      "'Apple Color Emoji'",
      "'Segoe UI Emoji'",
      "'Segoe UI Symbol'"
    ].join(",")
  }
};

export default rdaDarkThemeOptions;
