import React from "react";
import { useAppSelector } from "../redux/hooks";
import DraftController from "../repositories/DraftController";
import { IDraft } from "../types/IDraft";

type UseSingleDraftResponse = {
  isLoading: boolean;
  draft: IDraft | null;
  reload: (draftId: string) => Promise<void>;
};

export default function useSingleDraft(): UseSingleDraftResponse {
  const selectedBusiness = useAppSelector((state) => state.business.business);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [draft, setDraft] = React.useState<IDraft | null>(null);

  const reload = React.useCallback(
    async (draftId: string) => {
      setIsLoading(true);
      setDraft(null);
      if (selectedBusiness?.uuid) {
        const response = await DraftController.get(
          selectedBusiness.uuid,
          draftId
        );
        setDraft(response);
      }
      setIsLoading(false);
    },
    [selectedBusiness]
  );

  return {
    isLoading,
    draft,
    reload,
  };
}
