import React from "react";
import { useAppSelector } from "../redux/hooks";
import JournalController from "../repositories/JournalController";
import { IJournal } from "../types/IJournal";
import { IJournalRequestOptions } from "../types/IJournalRequestOptions";
import { IPaginationMeta } from "../types/IPaginationMeta";

type UseJournalsResponse = {
  isLoading: boolean;
  journals: IJournal[];
  error?: string;
  pagination?: IPaginationMeta;
  reload: (options: IJournalRequestOptions) => Promise<void>;
};

export default function useJournals(): UseJournalsResponse {
  const selectedBusiness = useAppSelector((state) => state.business.business);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [journals, setJournals] = React.useState<IJournal[]>([]);
  const [pagination, setPagination] = React.useState<IPaginationMeta>();
  const [error, setError] = React.useState<string>("");

  const reload = React.useCallback(
    async (options: IJournalRequestOptions) => {
      setIsLoading(true);
      setJournals([]);
      setPagination(undefined);
      setError("");
      if (selectedBusiness?.uuid) {
        const response = await JournalController.list(
          selectedBusiness.uuid,
          options
        );

        if (response.status === "success") {
          setJournals(response.data);
          setPagination(response.meta);
        }

        if (response.message) {
          setError(response.message);
        }
      }
      setIsLoading(false);
    },
    [selectedBusiness]
  );

  return {
    isLoading,
    journals,
    error,
    pagination,
    reload
  };
}
