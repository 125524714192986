import { Alert, Box, Paper, SxProps, Theme, Typography } from "@mui/material";
import React, { Suspense } from "react";
import { IBillto } from "../../../types/IBillto";
import { IInvoiceInfo } from "../../../types/IInvoiceInfo";
import { IJournal } from "../../../types/IJournal";
import { IJournalDoc } from "../../../types/IJournalDoc";
import { IPayment } from "../../../types/IPayment";
import { ITax } from "../../../types/ITax";
import LoadingBox from "../../atoms/LoadingBox";

const BilltoView = React.lazy(() => import("../../molecules/BilltoView"));
const InvoiceInfoView = React.lazy(
  () => import("../../molecules/InvoiceInfoView")
);
const EmptyBoxedMessage = React.lazy(
  () => import("../../atoms/EmptyBoxedMessage")
);
const DraftJournalsView = React.lazy(() => import("../../DraftJournalsView"));
const JournalsView = React.lazy(() => import("../../JournalsView"));

type InvoiceGridViewProps = {
  invoiceId?: string;
  additionalNote?: string;
  billTo?: IBillto;
  invoiceInfo?: IInvoiceInfo;
  journals?: IJournal[];
  drafts?: IJournalDoc[];
  isDraft?: boolean;
  totalTax?: string;
  totalPaid?: string;
  grandTotal?: string;
  subtotal?: string;
  taxes?: ITax[];
  payments?: IPayment[];
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
};

export default function InvoiceGridView({
  invoiceId,
  additionalNote,
  billTo,
  invoiceInfo,
  journals = [],
  drafts = [],
  isDraft = false,
  totalTax = "",
  totalPaid = "",
  grandTotal = "",
  subtotal = "",
  taxes = [],
  payments = [],
  children,
  sx = []
}: InvoiceGridViewProps) {
  return (
    <Paper
      variant="outlined"
      sx={[
        (theme) => ({
          display: "flex",
          flexDirection: "column",
          rowGap: 3,
          pt: theme.spacing(3)
        }),
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexWrap: "wrap",
          rowGap: 3,
          columnGap: 6,
          pl: theme.spacing(3),
          pr: theme.spacing(3),
          [theme.breakpoints.up("md")]: {
            flexDirection: "row"
          },
          [theme.breakpoints.down("md")]: {
            flexDirection: "column"
          }
        })}
      >
        <Box
          sx={{
            flex: 1,
            height: "100%"
          }}
        >
          {billTo ? (
            <Suspense fallback={<LoadingBox message="Loading..." />}>
              <BilltoView billto={billTo} />
            </Suspense>
          ) : (
            <Suspense fallback={<LoadingBox message="Loading..." />}>
              <EmptyBoxedMessage
                message="No bill-to detail."
                sx={{ height: "100%" }}
              />
            </Suspense>
          )}
        </Box>
        <Box
          sx={{
            flex: 1,
            height: "100%"
          }}
        >
          {invoiceInfo ? (
            <Suspense fallback={<LoadingBox message="Loading..." />}>
              <InvoiceInfoView
                invoiceInfo={invoiceInfo}
                totalTax={parseFloat(totalTax) || 0}
                totalPaid={parseFloat(totalPaid) || undefined}
                grandTotal={parseFloat(grandTotal) || 0}
                subtotal={parseFloat(subtotal) || 0}
                taxes={taxes}
                payments={payments}
              />
            </Suspense>
          ) : (
            <Suspense fallback={<LoadingBox message="Loading..." />}>
              <EmptyBoxedMessage
                message="No invoice detail."
                sx={{ height: "100%" }}
              />
            </Suspense>
          )}
        </Box>
      </Box>
      {!isDraft && journals.length > 0 && (
        <Suspense fallback={<LoadingBox message="Loading..." />}>
          <JournalsView
            journals={journals}
            sx={[
              { borderLeft: 0, borderRight: 0 },
              !additionalNote ? { borderBottom: 0, marginBottom: "3px" } : null
            ]}
          />
        </Suspense>
      )}
      {isDraft && drafts.length > 0 && invoiceInfo && (
        <Suspense fallback={<LoadingBox message="Loading..." />}>
          <DraftJournalsView
            invoiceId={invoiceId ?? ""}
            currency={invoiceInfo.currency}
            journals={drafts}
            sx={[
              { borderLeft: 0, borderRight: 0 },
              !additionalNote ? { borderBottom: 0, marginBottom: "3px" } : null
            ]}
          />
        </Suspense>
      )}
      {((isDraft && drafts.length === 0) ||
        (!isDraft && journals.length === 0)) && (
        <Alert
          elevation={0}
          severity="info"
          sx={[
            (theme) => ({
              display: "flex",
              justifyContent: "center",
              p: theme.spacing(3),
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0
            }),
            additionalNote
              ? {
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0
                }
              : null
          ]}
        >
          No journal in this invoice.
        </Alert>
      )}
      {additionalNote && (
        <Box
          sx={(theme) => ({
            pl: theme.spacing(3),
            pr: theme.spacing(3),
            mb: theme.spacing(3)
          })}
        >
          <Typography variant="h6">Additional Note</Typography>
          <Typography>{additionalNote}</Typography>
        </Box>
      )}
      {children}
    </Paper>
  );
}
