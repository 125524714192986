import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  BoxProps,
  Drawer,
  IconButton,
  ThemeProvider,
  Toolbar,
  styled,
  useMediaQuery,
  useTheme
} from "@mui/material";
import firebase from "firebase/compat/app";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useBusinesses from "../hooks/useBusinesses";
import { Image } from "../redeuComponents";
import { signIn, signOut } from "../redux/AuthSlice";
import { unload } from "../redux/BusinessSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import rdaDarkTheme from "../themes/rdaDarkTheme";
import BusinessSelector from "./BusinessSelector";
import ProfileMenu from "./ProfileMenu";
import SideBar from "./SideBar";

export default function AppNavBar() {
  const theme = useTheme();
  const isLargerScreens = useMediaQuery(theme.breakpoints.up("md"));

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const selectedBusiness = useAppSelector((state) => state.business.business);
  const { isLoading, businesses, reload, selectBusiness } = useBusinesses();
  const [openSideBar, setOpenSideBar] = React.useState<boolean>(false);

  const handleLogout = React.useCallback(async () => {
    await firebase.auth().signOut();
    dispatch(signOut());
    dispatch(unload());
    return navigate("/signin");
  }, []);

  const handleLogin = React.useCallback(async () => {
    return navigate("/signin");
  }, []);

  // Check if user is logged in
  React.useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((firebaseUser) => {
        if (firebaseUser && !user) {
          dispatch(signIn(firebaseUser));
        }
      });

    // Make sure we un-register Firebase observers when the component unmounts.
    return () => unregisterAuthObserver();
  }, [dispatch, signIn]);

  React.useEffect(() => {
    reload(100, 1); // Load 100 businesses of this user
  }, [user]);

  return (
    <AppBarBox id="rda-app-navbar">
      <AppBar position="static">
        <Toolbar>
          {user && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setOpenSideBar((prev) => !prev)}
            >
              <MenuIcon />
            </IconButton>
          )}
          {isLargerScreens && (
            <Box onClick={() => navigate("/")}>
              <Image
                src={require("../images/redeubooks-logo.png")}
                width={160}
                height={30}
                fit="contain"
              />
            </Box>
          )}
          {!isLoading && businesses?.length > 0 && (
            <ThemeProvider theme={rdaDarkTheme}>
              <BusinessSelector
                sx={{ ml: 2 }}
                size="small"
                selectedBusiness={selectedBusiness}
                businesses={businesses}
                onChangeBusiness={selectBusiness}
              />
            </ThemeProvider>
          )}
          <Box sx={{ flex: 1 }} />
          {pathname !== "/signin" && (
            <ThemeProvider theme={rdaDarkTheme}>
              <ProfileMenu
                user={user}
                onLogin={handleLogin}
                onLogout={handleLogout}
              />
            </ThemeProvider>
          )}
        </Toolbar>
      </AppBar>
      {user && (
        <Drawer open={openSideBar} onClose={() => setOpenSideBar(false)}>
          <SideBar onDismiss={() => setOpenSideBar(false)} />
        </Drawer>
      )}
    </AppBarBox>
  );
}

const AppBarBox = styled(Box)<BoxProps>({
  "@media print": {
    display: "none"
  }
});
