import { PaletteMode } from "@mui/material";
import { createSlice } from "@reduxjs/toolkit";

interface ThemeState {
  mode: PaletteMode;
}

const initialThemeState: ThemeState = {
  mode: "light", // Default
};

export const themeSlice = createSlice({
  name: "theme",
  initialState: initialThemeState,
  reducers: {
    toggle: (state) => {
      state.mode = state.mode === "dark" ? "light" : "dark";
    },
  },
});

export const { toggle } = themeSlice.actions;

export default themeSlice.reducer;
