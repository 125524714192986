import { Box, SxProps, Theme, Typography } from "@mui/material";
import { ICompany } from "../types/ICompany";

type CompanyPrintViewProps = {
  company: ICompany;
  sx?: SxProps<Theme>;
};

export default function CompanyPrintView({
  company,
  sx,
}: CompanyPrintViewProps) {
  // Create a string of "city, state, zipcode"
  const cityStateZip = Array<string>();
  if (company.address.city) {
    cityStateZip.push(company.address.city);
  }
  if (company.address.state) {
    cityStateZip.push(company.address.state);
  }
  if (company.address.zipcode) {
    cityStateZip.push(company.address.zipcode);
  }

  return (
    <Box sx={sx}>
      {company.name && (
        <Typography variant="h4" sx={{ mb: 1 }}>
          {company.name}
        </Typography>
      )}
      {company.uen && (
        <Typography>Business Registration No: {company.uen}</Typography>
      )}
      {company.address && (
        <Box sx={{ mt: 2, mb: 2 }}>
          {company.address.line1 && (
            <Typography>{company.address.line1}</Typography>
          )}
          {company.address.line2 && (
            <Typography>{company.address.line2}</Typography>
          )}
          {cityStateZip.length > 0 && (
            <Typography>{cityStateZip.join(", ")}</Typography>
          )}
          {company.address.country && (
            <Typography>{company.address.country}</Typography>
          )}
        </Box>
      )}
      {company.phone && <Typography>Phone: {company.phone}</Typography>}
      {company.mobile && <Typography>Mobile: {company.mobile}</Typography>}
      {company.email && <Typography>Email: {company.email}</Typography>}
      {company.website && <Typography>Website: {company.website}</Typography>}
    </Box>
  );
}
