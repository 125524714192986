import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import CopyAllRoundedIcon from "@mui/icons-material/CopyAllRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import {
  Box,
  ButtonGroup,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableCell,
  TableRow
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import getAmountInCurrency from "../../../helpers/getAmountInCurrency";
import getShortLocalDateFromString from "../../../helpers/getShortLocalDateFromString";
import { GradientButton, StyledButton } from "../../../styledMuiComponents";
import { IInvoiceSimple } from "../../../types/IInvoiceSimple";
import LinkRouterButton from "../../LinkRouterButton";

type InvoiceTableRowProps = {
  invoice: IInvoiceSimple;
};

export default function InvoiceTableRow({ invoice }: InvoiceTableRowProps) {
  const navigate = useNavigate();
  const customer = invoice.bill_to.company;
  const invoice_detail = invoice.invoice;

  // Handle menu click
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableRow className="rdr-invoice-table-row">
      <TableCell>{customer.name}</TableCell>
      <TableCell>{invoice_detail.number}</TableCell>
      <TableCell>
        {getShortLocalDateFromString(invoice_detail.invoice_date)}
      </TableCell>
      <TableCell>
        {getShortLocalDateFromString(invoice_detail.due_date)}
      </TableCell>
      <TableCell>{invoice.invoice.status}</TableCell>
      <TableCell>
        {invoice.grand_total
          ? getAmountInCurrency(invoice.invoice.currency, invoice.grand_total)
          : "-"}
      </TableCell>
      <TableCell>
        {invoice.invoice.status === "PAID" ? (
          <Box component="span">
            {getAmountInCurrency(invoice.invoice.currency, invoice.total_paid)}
          </Box>
        ) : (
          <GradientButton
            aria-label="Add new payment to this invoice"
            size="small"
            variant="contained"
            endIcon={<AddRoundedIcon />}
            component={LinkRouterButton}
            to={`/invoices/${invoice?.uuid}/payment`}
          >
            Add
          </GradientButton>
        )}
      </TableCell>
      <TableCell>
        <ButtonGroup
          variant="contained"
          size="small"
          aria-label="Action buttons group"
        >
          <GradientButton
            to={`/invoices/${invoice.uuid}/view`}
            component={LinkRouterButton}
          >
            View
          </GradientButton>
          <StyledButton onClick={handleOpenMenu}>
            <MoreVertRoundedIcon fontSize="small" />
          </StyledButton>
        </ButtonGroup>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "invoice-action-button"
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <MenuItem
            onClick={() => {
              handleClose(); // Close menu
              return window.open(
                `${window.location.origin}/invoices/${invoice.public_link}/print/${invoice.invoice.number}`
              );
            }}
          >
            <ListItemIcon>
              <OpenInNewRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Public View" secondary="For Print" />
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.location.origin}/invoices/${invoice.public_link}/print/${invoice.invoice.number}`
              );
              handleClose(); // Close menu
            }}
          >
            <ListItemIcon>
              <CopyAllRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Copy Public Link" secondary="For Client" />
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              handleClose(); // Close menu
              return navigate(`/invoices/create/${invoice.uuid}`);
            }}
          >
            <ListItemIcon>
              <ContentCopyRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Duplicate" secondary="Create New Invoice" />
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
}
