import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import {
  Box,
  Button,
  ButtonGroup,
  InputAdornment,
  Paper,
  styled,
  SxProps,
  TableCell,
  TableCellProps,
  TableContainer,
  TableRow,
  TableRowProps,
  TextField,
  Theme,
  Tooltip,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import getAmountInCurrency from "../../../helpers/getAmountInCurrency";
import { GradientButton } from "../../../styledMuiComponents";
import { IInvoice } from "../../../types/IInvoice";
import { IPaymentRequestOptionsItem } from "../../../types/IPaymentRequestOptions";
import ResponsiveTable from "../../atoms/ResponsiveTable";
import IResponsiveTableColumn from "../../atoms/ResponsiveTable/types/IResponsiveTableColumn";
import IResponsiveTableHeader from "../../atoms/ResponsiveTable/types/IResponsiveTableHeader";
import IResponsiveTableRow from "../../atoms/ResponsiveTable/types/IResponsiveTableRow";

type PaymentFormProps = {
  invoice: IInvoice;
  payments: IPaymentRequestOptionsItem[];
  businessCurrency: string;
  defaultShowForm?: boolean;
  onAddPayment: (i: IPaymentRequestOptionsItem[]) => void;
  onToggleForm: (o: boolean) => void;
  isSaving?: boolean;
  disabled?: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  sx?: SxProps<Theme>;
};

export default function PaymentForm({
  invoice,
  payments,
  businessCurrency,
  defaultShowForm = false,
  isSaving = false,
  disabled = false,
  onAddPayment,
  onToggleForm,
  onCancel,
  onSubmit,
  sx = []
}: PaymentFormProps) {
  const [paidAmount, setPaidAmount] = React.useState<string>("");
  const [method, setMethod] = React.useState<string>("");
  const [note, setNote] = React.useState<string>("");
  const [localAmount, setLocalAmount] = React.useState<string>("");
  const [paymentDate, setPaymentDate] = React.useState<Dayjs | null>(dayjs());
  const [showForm, setShowForm] = React.useState<boolean>(defaultShowForm);
  const theme = useTheme();
  const isLargerScreens = useMediaQuery(theme.breakpoints.up("sm"));

  const PAYMENT_FORM_HEADERS: IResponsiveTableHeader[] = React.useMemo(
    () => [
      {
        index: "payment-form-header-actions",
        label: "",
        lgSx: { width: "50px !important" },
        smSx: { display: "none" }
      },
      {
        index: "payment-form-header-payment-date",
        label: "Payment Date",
        lgSx: { width: "200px !important" }
      },
      {
        index: "payment-form-header-method",
        label: "Method"
      },
      {
        index: "payment-form-header-note",
        label: "Note"
      },
      {
        index: "payment-form-header-amount",
        label: (
          <Box
            sx={{
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "center",
              columnGap: 0.5
            }}
          >
            Amount
            <Tooltip title="Can support up to 2 decimal places" arrow>
              <HelpRoundedIcon
                fontSize="small"
                color="secondary"
                sx={{ verticalAlign: "text-bottom", ml: 0.5 }}
              />
            </Tooltip>
          </Box>
        ),
        lgSx: { width: "200px !important" }
      }
    ],
    []
  );

  const addItem = React.useCallback(() => {
    if (
      isNaN(Number(paidAmount)) ||
      isNaN(Number(localAmount)) ||
      method === ""
    ) {
      setShowForm(false);
      onToggleForm(false);
      return null; // Do nothing until user correct the mistake
    }

    const roundedPaidAmount = parseFloat(paidAmount).toFixed(2);
    const roundedLocalAmount = parseFloat(localAmount).toFixed(2);

    const newItems = [...payments];
    newItems.push({
      amount: roundedPaidAmount,
      currency: invoice.invoice.currency,
      payment_date: paymentDate ? paymentDate.toISOString() : "",
      method,
      note,
      local_amount: localAmount ? roundedLocalAmount : undefined,
      local_currency: localAmount ? businessCurrency : undefined
    });

    onAddPayment(newItems);
    // Reset to blank
    setPaidAmount("");
    setMethod("");
    setNote("");
    setLocalAmount("");
    setShowForm(false);
    onToggleForm(false);
  }, [
    invoice,
    businessCurrency,
    payments,
    onAddPayment,
    paidAmount,
    paymentDate,
    localAmount,
    method,
    note
  ]);

  const grandTotal = React.useMemo(() => {
    const grandAmount = payments.reduce(
      (accumulator, currentValue) =>
        accumulator + parseFloat(currentValue.amount),
      0
    );

    if (invoice?.invoice?.currency) {
      return getAmountInCurrency(invoice.invoice.currency, grandAmount);
    }

    return grandAmount.toFixed(2);
  }, [payments, invoice]);

  const grandLocalTotal = React.useMemo(() => {
    let local_currency = "";

    const grandAmount = payments.reduce((accumulator, currentValue) => {
      if (currentValue.local_amount && currentValue.local_currency) {
        local_currency = currentValue.local_currency;
        return accumulator + parseFloat(currentValue.local_amount);
      }
      return accumulator;
    }, 0);

    if (local_currency) {
      return getAmountInCurrency(local_currency, grandAmount);
    }

    return grandAmount.toFixed(2);
  }, [payments, invoice]);

  const headers: IResponsiveTableHeader[] = React.useMemo(() => {
    const _headers = PAYMENT_FORM_HEADERS;
    if (invoice.invoice.currency !== businessCurrency) {
      _headers.push({
        index: "payment-form-header-local-amount",
        label: (
          <Box
            sx={{
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "center",
              columnGap: 0.5
            }}
          >
            Local Amount
            <Tooltip
              title="Amount in Business currency, up to 2 decimal places"
              arrow
            >
              <HelpRoundedIcon
                fontSize="small"
                color="secondary"
                sx={{ verticalAlign: "text-bottom", ml: 0.5 }}
              />
            </Tooltip>
          </Box>
        )
      });
    }

    return _headers;
  }, [businessCurrency, invoice]);

  const paymentRows: IResponsiveTableRow[] = React.useMemo(() => {
    const _rows: IResponsiveTableRow[] = [];

    payments?.length > 0 &&
      payments.map((payment, i) => {
        const _cols: IResponsiveTableColumn[] = [
          {
            headerIdx: "payment-form-header-actions",
            uuid: `payment-form-header-actions-${i}`,
            label: (
              <ButtonGroup
                size="small"
                variant="contained"
                sx={{ mb: isLargerScreens ? 0 : 2 }}
              >
                <Button
                  onClick={() => removeItem(i)}
                  color="secondary"
                  size="small"
                >
                  <DeleteRoundedIcon />
                </Button>
                <Button
                  onClick={() => handleEditPayment(i, payment)}
                  color="primary"
                  size="small"
                >
                  <EditRoundedIcon />
                </Button>
              </ButtonGroup>
            )
          },
          {
            headerIdx: "payment-form-header-payment-date",
            uuid: `payment-form-header-payment-date-${i}`,
            label: dayjs(payment.payment_date).format("D-MMM-YYYY")
          },
          {
            headerIdx: "payment-form-header-method",
            uuid: `payment-form-header-method-${i}`,
            label: payment.method
          },
          {
            headerIdx: "payment-form-header-note",
            uuid: `payment-form-header-note-${i}`,
            label: payment.note
          },
          {
            headerIdx: "payment-form-header-amount",
            uuid: `payment-form-header-amount-${i}`,
            label: getAmountInCurrency(invoice.invoice.currency, payment.amount)
          }
        ];

        if (invoice.invoice.currency !== businessCurrency) {
          _cols.push({
            headerIdx: "payment-form-header-local-amount",
            uuid: `payment-form-header-local-amount-${i}`,
            label:
              payment.local_currency && payment.local_amount
                ? getAmountInCurrency(
                    payment.local_currency,
                    payment.local_amount
                  )
                : ""
          });
        }

        _rows.push({
          uuid: `payment-${i}`,
          columns: _cols
        });
      });
    return _rows;
  }, [payments]);

  const removeItem = React.useCallback(
    (index: number) => {
      const _rows = [...payments];
      _rows.splice(index, 1);
      onAddPayment(_rows);
    },
    [payments, onAddPayment]
  );

  const handleEditPayment = React.useCallback(
    (index: number, item: IPaymentRequestOptionsItem) => {
      setPaidAmount(item.amount);
      setMethod(item.method);
      setNote(item.note);
      setLocalAmount(item.local_amount ? item.local_amount : "");
      setPaymentDate(item.payment_date ? dayjs(item.payment_date) : null);
      setShowForm(true);
      removeItem(index);
    },
    [removeItem]
  );

  return (
    <TableContainer component={Paper} variant="outlined" sx={sx}>
      <ResponsiveTable
        headers={headers}
        rows={paymentRows}
        sx={{ borderRadius: 0 }}
      >
        {payments?.length > 0 && isLargerScreens && (
          <TableRow sx={{ backgroundColor: theme.palette.action.selected }}>
            <TableCell variant="head" colSpan={4} align="right">
              Grand Total
            </TableCell>
            <TableCell variant="head">{grandTotal}</TableCell>
            {invoice.invoice.currency !== businessCurrency && (
              <TableCell variant="head">{grandLocalTotal}</TableCell>
            )}
          </TableRow>
        )}
        {payments?.length > 0 && !isLargerScreens && (
          <GrandTotalMobileRow>
            <GrandTotalMobileCell>
              <Box component="span" className="header">
                Grand Total
              </Box>
              <Box component="span" className="label">
                {grandTotal}
              </Box>
            </GrandTotalMobileCell>
            {invoice.invoice.currency !== businessCurrency && (
              <GrandTotalMobileCell>
                <Box component="span" className="header">
                  Local Total
                </Box>
                <Box component="span" className="label">
                  {grandLocalTotal}
                </Box>
              </GrandTotalMobileCell>
            )}
          </GrandTotalMobileRow>
        )}
      </ResponsiveTable>
      {showForm ? (
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: isLargerScreens ? "row" : "column",
            gap: 2,
            p: 3,
            backgroundColor: theme.palette.action.hover
          })}
        >
          <DatePicker
            autoFocus
            label="Payment Date"
            value={paymentDate}
            onChange={(newValue, context) => {
              if (!context.validationError) {
                setPaymentDate(newValue);
              }
            }}
            closeOnSelect
            minDate={dayjs(invoice.invoice.invoice_date)}
            maxDate={dayjs().add(7, "day")}
            sx={{ width: "100%" }}
            slotProps={{ textField: { size: "small" } }}
          />
          <TextField
            size="small"
            fullWidth={true}
            id="inputMethod"
            label="Payment Method"
            value={method}
            onChange={(e) => setMethod(e.target.value)}
          />
          <TextField
            size="small"
            fullWidth={true}
            id="inputNote"
            label="Payment Note"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
          <TextField
            size="small"
            fullWidth={true}
            id="inputAmount"
            label="Amount"
            value={paidAmount}
            onChange={(e) => setPaidAmount(e.target.value)}
            error={isNaN(Number(paidAmount))}
            helperText={isNaN(Number(paidAmount)) && "Numbers only."}
            onKeyDown={(e) => {
              if (
                invoice.invoice.currency === businessCurrency &&
                e.key === "Enter"
              ) {
                addItem();
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {invoice.invoice.currency}
                </InputAdornment>
              )
            }}
          />
          {invoice.invoice.currency !== businessCurrency && (
            <TextField
              size="small"
              fullWidth={true}
              id="inputLocalAmount"
              label="Local Amount"
              value={localAmount}
              onChange={(e) => setLocalAmount(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  addItem();
                }
              }}
              error={isNaN(Number(localAmount))}
              helperText={isNaN(Number(localAmount)) && "Numbers only."}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {businessCurrency}
                  </InputAdornment>
                )
              }}
            />
          )}
          <ButtonGroup
            variant="contained"
            aria-label="Journal Form Button Group"
            fullWidth
          >
            <Button startIcon={<AddRoundedIcon />} onClick={() => addItem()}>
              Add
            </Button>
            <Button
              onClick={() => {
                setShowForm(false);
                onToggleForm(false);
              }}
              color="secondary"
            >
              <DeleteRoundedIcon />
            </Button>
          </ButtonGroup>
        </Box>
      ) : (
        <Box
          sx={(theme) => ({
            p: 3,
            backgroundColor: theme.palette.action.hover
          })}
        >
          <Button
            variant="text"
            endIcon={<AddRoundedIcon />}
            onClick={() => {
              setShowForm(true);
              onToggleForm(true);
            }}
            color="secondary"
            fullWidth={true}
          >
            Add new line
          </Button>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: isLargerScreens ? "row" : "column-reverse",
          flexWrap: "wrap",
          justifyContent: "space-between",
          gap: 2,
          p: 3
        }}
      >
        <Button variant="outlined" onClick={onCancel}>
          CANCEL
        </Button>
        <GradientButton
          variant="contained"
          color="secondary"
          endIcon={<SaveRoundedIcon />}
          disabled={disabled}
          onClick={onSubmit}
          to=""
        >
          {isSaving ? "SAVING PAYMENT" : "SAVE PAYMENT"}
        </GradientButton>
      </Box>
    </TableContainer>
  );
}

const GrandTotalMobileRow = styled(TableRow)<TableRowProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  padding: theme.spacing(3),
  backgroundColor: theme.palette.action.selected
}));

const GrandTotalMobileCell = styled(TableCell)<TableCellProps>(({ theme }) => ({
  border: 0,
  padding: 0,
  display: "flex",
  justifyContent: "space-between",
  "& .header": {
    color: theme.palette.grey[500]
  },
  "& .label": {
    flex: 1,
    textAlign: "right"
  }
}));
