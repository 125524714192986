import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./AuthSlice";
import businessReducer from "./BusinessSlice";
import themeReducer from "./ThemeSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    business: businessReducer,
    theme: themeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {auth: AuthState, business: BusinessState}
export type AppDispatch = typeof store.dispatch;

export default store;
