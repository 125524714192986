import React from "react";
import InvoiceController from "../repositories/InvoiceController";
import { IInvoice } from "../types/IInvoice";

type UsePrintInvoiceResponse = {
  isLoading: boolean;
  invoice: IInvoice | null;
  error?: string;
  reload: (publicLink: string, invoiceNumber: string) => Promise<void>;
};

export default function usePrintInvoice(): UsePrintInvoiceResponse {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [invoice, setInvoice] = React.useState<IInvoice | null>(null);
  const [error, setError] = React.useState<string>("");

  const reload = React.useCallback(
    async (publicLink: string, invoiceNumber: string) => {
      setIsLoading(true);
      setInvoice(null);
      setError("");
      try {
        const response = await InvoiceController.getPublicLink(
          publicLink,
          invoiceNumber
        );
        if (response.status === "success") {
          if (response.data) {
            setInvoice(response.data);
          } else {
            setError("No invoice returned.");
          }
        } else {
          setError(response.message);
        }
      } catch (e) {
        // Error
      }

      setIsLoading(false);
    },
    []
  );

  return {
    isLoading,
    invoice,
    error,
    reload,
  };
}
