import { Box, Paper, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import getAmountInCurrency from "../../../helpers/getAmountInCurrency";
import {
  GradientButton,
  StyledTablePagination
} from "../../../styledMuiComponents";
import { IJournal } from "../../../types/IJournal";
import { IPaginationMeta } from "../../../types/IPaginationMeta";
import HeaderContainerWithFilter from "../../HeaderContainerWithFilter";
import LinkRouterButton from "../../LinkRouterButton";
import ResponsivePagination from "../../atoms/ResponsivePagination";
import ResponsiveTable from "../../atoms/ResponsiveTable";
import IResponsiveTableColumn from "../../atoms/ResponsiveTable/types/IResponsiveTableColumn";
import IResponsiveTableHeader from "../../atoms/ResponsiveTable/types/IResponsiveTableHeader";
import IResponsiveTableRow from "../../atoms/ResponsiveTable/types/IResponsiveTableRow";
import TablePaginationActions from "../../atoms/TablePaginationActions";

type JournalTableProps = {
  title?: string;
  journals: IJournal[];
  pagination?: IPaginationMeta;
  selector?: React.ReactNode;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (rowsPerPage: number) => void;
};

export const JOURNAL_TABLE_HEADERS: IResponsiveTableHeader[] = [
  {
    index: "header-name",
    label: "Name"
  },
  {
    index: "header-description",
    label: "Description"
  },
  {
    index: "header-quantity",
    label: "Quantity"
  },
  {
    index: "header-unit-price",
    label: "Unit Price"
  },
  {
    index: "header-credit",
    label: "Credit"
  },
  {
    index: "header-debit",
    label: "Debit"
  },
  {
    index: "header-amount",
    label: "Amount"
  },
  {
    index: "header-actions",
    label: "Actions"
  }
];

export default function JournalTable({
  journals,
  title,
  pagination,
  selector,
  onChangePage,
  onChangeRowsPerPage
}: JournalTableProps) {
  const theme = useTheme();
  const isLargerScreens = useMediaQuery(theme.breakpoints.up("md"));
  const location = useLocation();
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    onChangePage(newPage + 1); // Pagination UI is 0 index, but server starts from 1.
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onChangeRowsPerPage(parseInt(event.target.value, 10));
    onChangePage(1); // Pagination UI is 0 index, but server starts from 1.
  };

  const journalRows: IResponsiveTableRow[] = React.useMemo(() => {
    const _rows: IResponsiveTableRow[] = [];
    if (journals && journals.length > 0) {
      journals.map((journal) => {
        const cols: IResponsiveTableColumn[] = [
          {
            headerIdx: "header-name",
            uuid: `${journal.uuid}-name`,
            label: journal.document.name
          },
          {
            headerIdx: "header-description",
            uuid: `${journal.uuid}-description`,
            label: journal.document.description
          },
          {
            headerIdx: "header-quantity",
            uuid: `${journal.uuid}-quantity`,
            label: journal.document.quantity
          },
          {
            headerIdx: "header-unit-price",
            uuid: `${journal.uuid}-unit-price`,
            label: getAmountInCurrency(
              journal.currency,
              journal.document.unit_price
            )
          },
          {
            headerIdx: "header-credit",
            uuid: `${journal.uuid}-credit`,
            label: journal.credit
          },
          {
            headerIdx: "header-debit",
            uuid: `${journal.uuid}-debit`,
            label: journal.debit
          },
          {
            headerIdx: "header-amount",
            uuid: `${journal.uuid}-amount`,
            label: getAmountInCurrency(journal.currency, journal.amount)
          },
          {
            headerIdx: "header-actions",
            uuid: `${journal.uuid}-actions`,
            label: (
              <GradientButton
                to={`/invoices/${journal.parent}/view`}
                component={LinkRouterButton}
                size="small"
                state={{ from: location.pathname }}
              >
                Invoice
              </GradientButton>
            )
          }
        ];
        const row: IResponsiveTableRow = {
          uuid: journal.uuid,
          columns: cols
        };
        _rows.push(row);
      });
    }

    return _rows;
  }, [journals]);

  return (
    <React.Fragment>
      <HeaderContainerWithFilter title={title}>
        {selector && (
          <React.Fragment>
            <Box sx={{ flex: 1 }} />
            {selector}
          </React.Fragment>
        )}
      </HeaderContainerWithFilter>
      {journals?.length > 0 && (
        <React.Fragment>
          <Paper variant="outlined" sx={{ overflow: "hidden" }}>
            <ResponsiveTable
              headers={JOURNAL_TABLE_HEADERS}
              rows={journalRows}
            />
            {pagination && isLargerScreens && (
              <StyledTablePagination
                component={Paper}
                rowsPerPageOptions={[5, 10, 25, 50]}
                colSpan={7}
                count={pagination.total}
                rowsPerPage={pagination.per_page}
                page={pagination.current_page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            )}
            {pagination && !isLargerScreens && (
              <ResponsivePagination
                pagination={pagination}
                onChangePage={onChangePage}
              />
            )}
          </Paper>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
