const getAmountInCurrency = (currency: string, amount: string | number) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  });

  return formatter.format(Number(amount));
};

export default getAmountInCurrency;
