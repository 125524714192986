import { Table, TableProps, styled } from "@mui/material";

const StyledResponsiveTable = styled(Table)<TableProps>(({ theme }) => ({
  tableLayout: "auto",
  width: "100%",
  "& th, td": {
    width: "auto"
  },
  "& .small-screen-row-title": {
    display: "none"
  },
  "&.small-screen": {
    display: "flex",
    flexDirection: "column",
    "& .small-screen-row-title": {
      display: "flex",
      fontWeight: "bold",
      alignItems: "center",
      fontSize: "0.875rem",
      position: "relative",
      "&.hidden": {
        display: "none"
      },
      "&:after": {
        content: "':'",
        display: "block",
        position: "absolute",
        right: `-${theme.spacing(1)}`
      }
    },
    "& > thead": {
      display: "none"
    },
    "& > tbody": {
      display: "block",
      flexDirection: "column",
      "& > tr": {
        display: "grid",
        gridColumnGap: theme.spacing(2),
        gridRowGap: theme.spacing(1),
        gridTemplateColumns: "max-content 1fr",
        padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        "& > th": {
          fontWeight: "bold"
        },
        "& > td, th": {
          display: "block",
          padding: 0,
          borderBottom: 0,
          wordBreak: "break-word"
        },
        // For single column form row
        "&.single-responsive-form": {
          gridTemplateColumns: "1fr",
          "& > td, th": {
            paddingBottom: theme.spacing(1)
          }
        }
      }
    }
  }
}));

export default StyledResponsiveTable;
