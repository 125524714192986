import { Paper, SxProps, Theme, Typography } from "@mui/material";
import React from "react";

type EmptyBoxedMessageProps = {
  title?: string;
  message?: string;
  action?: React.ReactNode;
  sx?: SxProps<Theme>;
};

export default function EmptyBoxedMessage({
  title,
  message,
  action,
  sx = []
}: EmptyBoxedMessageProps) {
  return (
    <Paper
      variant="outlined"
      sx={[
        (theme) => ({
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderStyle: "dashed",
          padding: theme.spacing(3)
        }),
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      {title && (
        <Typography sx={{ fontSize: "1.5rem", fontWeight: "bold" }}>
          {title}
        </Typography>
      )}
      {message && (
        <Typography sx={{ fontSize: "1rem", mb: action ? 2 : 0 }}>
          {message}
        </Typography>
      )}
      {!title && !message && (
        <Typography sx={{ fontSize: "1rem", mb: action ? 2 : 0 }}>
          No message.
        </Typography>
      )}
      {action}
    </Paper>
  );
}
