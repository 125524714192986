import { ListItemButton, ListItemButtonProps, styled } from "@mui/material";
import { LinkProps } from "react-router-dom";

const RouterListItemButton = styled(ListItemButton)<
  ListItemButtonProps & LinkProps
>(() => ({
  // Inherit all args from parents
  // Add prop "to" from RouterProps into ListItemButton
}));

export default RouterListItemButton;
