import { Box, BoxProps, styled } from "@mui/material";

const LoadingContainer = styled(Box)<BoxProps>({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
});

export default LoadingContainer;
