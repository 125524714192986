import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Provider as ReduxProvider } from "react-redux";
import "./App.css";
import ThemedApp from "./ThemedApp";
import store from "./redux/store";

export default function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ReduxProvider store={store}>
        <ThemedApp />
      </ReduxProvider>
    </LocalizationProvider>
  );
}
