import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import { Alert, Box, Grid, Paper } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import React, { Suspense } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AdditionalNoteForm from "../components/AdditionalNoteForm";
import BilltoForm from "../components/BilltoForm";
import BilltoSearchForm from "../components/BilltoSearchForm";
import InvoiceForm from "../components/InvoiceForm";
import JournalsForm from "../components/JournalsForm";
import LoadingBox from "../components/atoms/LoadingBox";
import TitledPageTemplate from "../components/templates/TitledPageTemplate";
import paymentTermsJson from "../config/paymentterms.json";
import useCustomer from "../hooks/useCustomer";
import useCustomers from "../hooks/useCustomers";
import useTaxes from "../hooks/useTaxes";
import { useAppSelector } from "../redux/hooks";
import DraftController from "../repositories/DraftController";
import InvoiceController from "../repositories/InvoiceController";
import { GradientButton } from "../styledMuiComponents";
import { IBillto } from "../types/IBillto";
import { ICustomerSimple } from "../types/ICustomerSimple";
import { IDraftCreateRequestOptions } from "../types/IDraftCreateRequestOptions";
import { IInvoiceInfo } from "../types/IInvoiceInfo";
import { IJournal } from "../types/IJournal";
import { IJournalDoc } from "../types/IJournalDoc";
import { IPaymentTerm } from "../types/IPaymentTerm";
import { ITax } from "../types/ITax";

const BilltoView = React.lazy(
  () => import("../components/molecules/BilltoView")
);

export default function InvoiceCreationScreen() {
  const { invoiceId } = useParams();
  const navigate = useNavigate();
  const selectedBusiness = useAppSelector((state) => state.business.business);
  const [saveError, setSaveError] = React.useState<string>("");
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [editBillto, setEditBillto] = React.useState<boolean>(false);
  const [billto, setBillto] = React.useState<IBillto | null>(null);
  const [invoiceInfo, setInvoiceInfo] = React.useState<IInvoiceInfo | null>(
    null
  );
  const [journals, setJournals] = React.useState<IJournal[]>([]);
  const [journalFormDirty, setJournalFormDirty] =
    React.useState<boolean>(false);
  const [additionalNote, setAdditionalNote] = React.useState<string>("");
  const [additionalNoteDirty, setAdditionalNoteDirty] =
    React.useState<boolean>(false);

  const [selectedCustomer, setSelectedCustomer] =
    React.useState<ICustomerSimple | null>(null);

  // For Invoice Form
  const paymentTerms = paymentTermsJson as IPaymentTerm[]; // Cast type to json data
  const [dueDate, setDueDate] = React.useState<Dayjs | null>(dayjs());
  const [currency, setCurrency] = React.useState<string | null>(
    selectedBusiness?.currency ? selectedBusiness.currency : "SGD"
  );
  const [purchaseOrder, setPurchaseOrder] = React.useState<string>("");
  const [paymentTerm, setPaymentTerm] = React.useState<IPaymentTerm | null>(
    paymentTerms[0]
  );
  const [tax, setTax] = React.useState<ITax | null>(null);
  const { loading: loadingTaxes, taxes } = useTaxes();
  const {
    customers,
    isLoading: loadingCustomers,
    reload
  } = useCustomers<ICustomerSimple>(1000, 1);

  const { customer, getCustomer, clear: clearCustomer } = useCustomer();

  const [hasPermission, setHasPermission] = React.useState<boolean>(false);
  const [permissionError, setPermissionError] = React.useState<string>("");

  React.useEffect(() => {
    if (selectedBusiness?.uuid) {
      InvoiceController.hasPermission()
        .then((permission) => {
          setHasPermission(permission.status === "success");
          setPermissionError(permission.message);
        })
        .catch(() => {
          setHasPermission(false);
          setPermissionError("Unknown error occured when checking permission.");
        });
    }
  }, [selectedBusiness]);

  React.useEffect(() => {
    if (selectedBusiness?.uuid) {
      // Reload customer list
      reload(selectedBusiness.uuid);
    }
  }, [selectedBusiness]);

  React.useEffect(() => {
    if (selectedBusiness?.uuid && selectedCustomer) {
      getCustomer(selectedBusiness.uuid, selectedCustomer.uuid);
    } else {
      clearCustomer();
    }
  }, [selectedBusiness, selectedCustomer]);

  // Duplicate invoice
  React.useEffect(() => {
    if (invoiceId && selectedBusiness && selectedBusiness.uuid) {
      InvoiceController.get(selectedBusiness.uuid, invoiceId).then(
        (invoice) => {
          if (invoice) {
            const _cust = customers.find((c) => c.uuid === invoice.customer_id);
            setBillto(invoice.bill_to);
            setAdditionalNote(invoice.additional_note ?? "");
            setInvoiceInfo(invoice.invoice);
            setSelectedCustomer(_cust ? _cust : null);
            setJournals(invoice.journals);
            setCurrency(invoice.invoice.currency);
            const _term = paymentTerms.find(
              (pt) => pt.name === invoice.invoice.payment_term
            );
            setPaymentTerm(_term ? _term : paymentTerms[0]);
            setPurchaseOrder(
              invoice.invoice?.purchase_order
                ? invoice.invoice.purchase_order
                : ""
            );
            if (invoice.taxes && invoice.taxes.length > 0) {
              const _tax = invoice.taxes[0];
              const _selectedTax = taxes.find((t) => t.name === _tax.name);
              setTax(_selectedTax ?? null);
            }
          }
        }
      );
    }
  }, [
    invoiceId,
    customers,
    selectedBusiness,
    loadingTaxes,
    loadingCustomers,
    taxes
  ]);

  React.useEffect(() => {
    if (paymentTerm) {
      // Set due date based on payment term
      setDueDate(dayjs().add(paymentTerm.amount, paymentTerm.unit));
    }
  }, [paymentTerm]);

  React.useEffect(() => {
    if (!dueDate || !currency || !paymentTerm) {
      // No due date or currency, do not save
      setInvoiceInfo(null);
      return;
    }

    setInvoiceInfo({
      currency: currency,
      due_date: dueDate.toISOString(),
      invoice_date: dayjs().toISOString(),
      number: "",
      purchase_order: purchaseOrder ? purchaseOrder : undefined,
      payment_term: paymentTerm.name,
      status: "OUTSTANDING"
    });
  }, [dueDate, currency, purchaseOrder, paymentTerm]);

  /**
   * Save invoice to server
   */
  const save = React.useCallback(async () => {
    if (!selectedBusiness || !selectedBusiness.uuid) {
      return setSaveError("No business ID found.");
    }

    if (!invoiceInfo) {
      return setSaveError("Incomplete invoice information found.");
    }

    const uploadBillto = customer ? customer.document : billto;
    if (!uploadBillto) {
      return setSaveError("No bill-to information provided.");
    }

    setIsSaving(true);

    const formattedDueDate = (dueDate ?? dayjs()).toISOString();
    const drafts = journals.reduce((accumulate, current) => {
      accumulate.push(current.document); // Only need the doc within IJournal
      return accumulate;
    }, Array<IJournalDoc>());

    const content: IDraftCreateRequestOptions = {
      invoice_id: "",
      customer_id: selectedCustomer?.uuid,
      bill_to: uploadBillto,
      currency: invoiceInfo?.currency,
      due_date: formattedDueDate,
      payment_term: invoiceInfo?.payment_term,
      purchase_order: invoiceInfo?.purchase_order,
      drafts: drafts,
      taxes: tax ? [tax] : undefined,
      additional_note: additionalNote
    };

    const result = await DraftController.create(
      selectedBusiness.uuid,
      JSON.stringify(content)
    );

    setIsSaving(false);
    if (result && result.status === "success") {
      return navigate("/drafts", { replace: true });
    } else {
      return setSaveError(
        result?.message ?? "Unable to save the invoice, please try again."
      );
    }
  }, [
    selectedCustomer,
    invoiceInfo,
    billto,
    journals,
    selectedBusiness,
    tax,
    additionalNote,
    dueDate
  ]);

  return (
    <TitledPageTemplate
      containerId="rda-screen-invoice-creation"
      title="Create New Invoice"
      onClickBack={() => navigate(-1)}
    >
      <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Paper
              variant="outlined"
              sx={(theme) => ({
                flex: 1,
                padding: theme.spacing(3),
                minHeight: "100%"
              })}
            >
              <Suspense fallback={<LoadingBox message="Loading..." />}>
                <BilltoView
                  billto={billto && !editBillto ? billto : customer?.document}
                  startAction={
                    !editBillto && (
                      <BilltoSearchForm
                        selectedCustomer={selectedCustomer}
                        customers={customers}
                        onChange={(cust) => {
                          setBillto(null); // Remove any changes to billto
                          setSelectedCustomer(cust); // Set the new customer
                        }}
                        isLoading={loadingCustomers}
                        size="small"
                        sx={(theme) => ({ marginBottom: theme.spacing(2) })}
                        actionButtonLabel="ADD NEW"
                        onActionButtonClick={() => {
                          setSelectedCustomer(null); // Remove any previous selected customer
                          setEditBillto(true); // Set the new bill to
                        }}
                      />
                    )
                  }
                  endAction={
                    editBillto && (
                      <BilltoForm
                        billto={billto}
                        setBillto={setBillto}
                        onDismiss={() => setEditBillto(false)}
                      />
                    )
                  }
                />
              </Suspense>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper
              variant="outlined"
              sx={(theme) => ({
                flex: 1,
                padding: theme.spacing(3),
                minHeight: "100%"
              })}
            >
              <InvoiceForm
                paymentTerms={paymentTerms}
                invoiceDate={dayjs()}
                currency={currency}
                setCurrency={setCurrency}
                dueDate={dueDate}
                setDueDate={setDueDate}
                purchaseOrder={purchaseOrder}
                setPurchaseOrder={setPurchaseOrder}
                paymentTerm={paymentTerm}
                setPaymentTerm={setPaymentTerm}
                tax={tax}
                setTax={setTax}
                taxes={taxes}
                loadingTaxes={loadingTaxes}
              />
            </Paper>
          </Grid>
        </Grid>
        {journals && (
          <Paper variant="outlined">
            <JournalsForm
              currency={invoiceInfo?.currency}
              journals={journals}
              onAddJournal={setJournals}
              onToggleForm={setJournalFormDirty}
              tax={tax}
            />
          </Paper>
        )}
        <AdditionalNoteForm
          note={additionalNote}
          onAddNote={setAdditionalNote}
          onToggleForm={setAdditionalNoteDirty}
        />
        <Paper
          variant="outlined"
          sx={(theme) => ({ padding: theme.spacing(3) })}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ flex: 1 }} />
            {additionalNoteDirty && (
              <Alert variant="filled" severity="error" sx={{ mr: 2 }}>
                Additional Note form is opened. Have you added the note?
              </Alert>
            )}
            {journalFormDirty && (
              <Alert variant="filled" severity="error" sx={{ mr: 2 }}>
                Journal form is opened. Have you added the journal?
              </Alert>
            )}
            {!invoiceInfo && (
              <Alert variant="filled" severity="error" sx={{ mr: 2 }}>
                Invoice form is not complete.
              </Alert>
            )}
            {!billto && !selectedCustomer && (
              <Alert variant="filled" severity="error" sx={{ mr: 2 }}>
                No Bill-to selected.
              </Alert>
            )}
            {saveError && (
              <Alert variant="filled" severity="error" sx={{ mr: 2 }}>
                {saveError}
              </Alert>
            )}
            {permissionError && (
              <Alert
                id="permission-error-alert"
                severity="error"
                variant="filled"
                sx={{ mr: 2 }}
              >
                {permissionError}
              </Alert>
            )}
            <GradientButton
              variant="contained"
              color="secondary"
              endIcon={<SaveRoundedIcon />}
              disabled={
                isSaving ||
                journalFormDirty ||
                journals?.length === 0 ||
                !invoiceInfo ||
                (!billto && !selectedCustomer) ||
                !hasPermission ||
                additionalNoteDirty
              }
              onClick={() => save()}
              to=""
            >
              {isSaving ? "SAVING DRAFT" : "SAVE AS DRAFT"}
            </GradientButton>
          </Box>
        </Paper>
      </Box>
    </TitledPageTemplate>
  );
}
