import { Box, BoxProps, SxProps, Theme, styled } from "@mui/material";
import * as React from "react";
import "./GradientBackground.css";

type GradientBackgroundProps = {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

export default function GradientBackground({
  children,
  sx = []
}: GradientBackgroundProps) {
  return (
    <ContainerBox className="rda-gradient-background" sx={sx}>
      {children}
    </ContainerBox>
  );
}

const ContainerBox = styled(Box)<BoxProps>({
  display: "flex",
  flex: 1
  // Background Gradient is defined in css file
});
