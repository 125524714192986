import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import EmptyBoxedMessage from "../components/atoms/EmptyBoxedMessage";
import CustomerSearchForm from "../components/CustomerSearchForm";
import InvoiceStatusSelector from "../components/InvoiceStatusSelector";
import InvoiceTable from "../components/organisms/InvoiceTable";
import TitledPageTemplate from "../components/templates/TitledPageTemplate";
import useCustomers from "../hooks/useCustomers";
import useInvoices from "../hooks/useInvoices";
import { useAppSelector } from "../redux/hooks";
import InvoiceController from "../repositories/InvoiceController";
import { ICustomerSimple } from "../types/ICustomerSimple";
import { InvoiceStatusType } from "../types/IInvoiceInfo";

export default function InvoicesScreen() {
  const navigate = useNavigate();
  const selectedBusiness = useAppSelector((state) => state.business.business);
  const { isLoading, invoices, pagination, reload } = useInvoices();
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(25);
  const [invoiceStatus, setInvoiceStatus] =
    React.useState<InvoiceStatusType>("ALL");
  const [hasPermission, setHasPermission] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");
  const {
    customers,
    isLoading: isLoadingCustomers,
    reload: reloadCustomers
  } = useCustomers<ICustomerSimple>(1000, 1);
  const [selectedCustomer, setSelectedCustomer] =
    React.useState<ICustomerSimple | null>(null);

  React.useEffect(() => {
    InvoiceController.hasPermission()
      .then((permission) => {
        setHasPermission(permission.status === "success");
        setError(permission.message);
      })
      .catch(() => {
        setHasPermission(false);
        setError("Unknown error occured when checking permission.");
      });
  }, [isLoading]);

  React.useEffect(() => {
    reload(
      rowsPerPage,
      invoiceStatus,
      page,
      selectedCustomer ? selectedCustomer.uuid : undefined
    ); // Get invoices
  }, [selectedBusiness, page, rowsPerPage, invoiceStatus, selectedCustomer]);

  // Get customers
  React.useEffect(() => {
    if (selectedBusiness?.uuid) {
      reloadCustomers(selectedBusiness.uuid);
    }
  }, [selectedBusiness]);

  return (
    <TitledPageTemplate
      containerId="rda-screen-invoices"
      title="Invoices"
      isLoading={isLoading}
      isLoadingMessage={`loading ${invoiceStatus} invoices...`}
    >
      {selectedBusiness && !isLoading && (
        <InvoiceTable
          invoices={invoices}
          title={`${invoiceStatus} invoices`}
          pagination={pagination}
          onChangePage={(p: number) => setPage(p)}
          onChangeRowsPerPage={(perPage: number) => setRowsPerPage(perPage)}
          error={error}
          onClickAction={() => navigate("/invoices/create")}
          disabledAction={!hasPermission}
          selector={
            <>
              <InvoiceStatusSelector
                size="small"
                sx={{ minWidth: 120 }}
                invoiceStatus={invoiceStatus}
                onChangeStatus={(value) => setInvoiceStatus(value)}
              />
              {customers?.length > 0 && (
                <CustomerSearchForm
                  customers={customers}
                  selectedCustomer={selectedCustomer}
                  onChange={setSelectedCustomer}
                  isLoading={isLoadingCustomers}
                  sx={{ minWidth: 120, flex: 1 }}
                />
              )}
            </>
          }
        />
      )}
      {!isLoading && invoices.length === 0 && (
        <EmptyBoxedMessage
          title="No Invoice Found"
          message="You can start by creating a new invoice."
          action={
            <Button
              startIcon={<AddRoundedIcon />}
              aria-label="Add a new invoice"
              onClick={() => navigate("/invoices/create")}
              variant="contained"
              color="secondary"
              sx={{ whiteSpace: "nowrap" }}
            >
              Add Invoice
            </Button>
          }
        />
      )}
    </TitledPageTemplate>
  );
}
