import { IHttpResponse } from "../types/IHttpResponse";
import { IPayment } from "../types/IPayment";
import Http from "./HttpController";

class PaymentController {
  /**
   * Represents a Payment.
   * @constructor
   */
  constructor() {
    return this;
  }

  /**
   * Edit existing payments
   * Content must be pre-stringified
   */
  static async add(
    business_id: string,
    content: string
  ): Promise<IHttpResponse<IPayment[]> | null> {
    const url = `/api/businesses/${business_id}/payments`;

    try {
      const result = await Http.post<IHttpResponse<IPayment[]>>(
        url,
        content,
        false
      );
      return result;
    } catch (e) {
      console.error(e);
    }

    return null;
  }
}

export default PaymentController;
