import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import Brightness4RoundedIcon from "@mui/icons-material/Brightness4Rounded";
import Brightness7RoundedIcon from "@mui/icons-material/Brightness7Rounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import StoreRoundedIcon from "@mui/icons-material/StoreRounded";
import {
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import firebase from "firebase/compat/app";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toggle } from "../redux/ThemeSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";

type ProfileMenuProps = {
  user: firebase.User | null;
  onLogout: () => void;
  onLogin: () => void;
};

export default function ProfileMenu({
  user,
  onLogout,
  onLogin,
}: ProfileMenuProps) {
  const navigate = useNavigate();
  const themeMode = useAppSelector((state) => state.theme.mode);
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!user) {
    return (
      <Button
        color="inherit"
        onClick={onLogin}
        startIcon={<PersonRoundedIcon />}
      >
        Login
      </Button>
    );
  }

  return (
    <Box id="profile-menu">
      <IconButton
        id="profile-button"
        aria-label="Profile Menu Button"
        aria-controls={open ? "profile-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <AccountCircleRoundedIcon />
      </IconButton>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "profile-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <ManageAccountsRoundedIcon />
          </ListItemIcon>
          <ListItemText
            primary="My Profile"
            secondary={user.displayName || user.email}
          />
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            navigate("/businesses");
            handleClose();
          }}
        >
          <ListItemIcon>
            <StoreRoundedIcon />
          </ListItemIcon>
          <ListItemText primary="Businesses" secondary="Add/Edit Businesses" />
        </MenuItem>
        <MenuItem onClick={() => dispatch(toggle())}>
          <ListItemIcon>
            {themeMode === "dark" ? (
              <Brightness4RoundedIcon />
            ) : (
              <Brightness7RoundedIcon />
            )}
          </ListItemIcon>
          <ListItemText
            primary="Toggle Theme"
            secondary={`Switch to ${
              themeMode === "dark" ? "light" : "dark"
            } mode`}
          />
        </MenuItem>
        <Divider />
        <MenuItem onClick={onLogout}>
          <ListItemIcon>
            <LogoutRoundedIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
    </Box>
  );
}
