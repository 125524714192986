import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Alert, Box, Button, Paper, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import BusinessEditor from "../components/BusinessEditor";
import LoadingBox from "../components/atoms/LoadingBox";
import useBusiness from "../hooks/useBusiness";
import BusinessController from "../repositories/BusinessController";
import { LayoutContainer, PageTitleTypography } from "../styledMuiComponents";
import { IBusiness } from "../types/IBusiness";
import IBusinessCreateRequestOptions from "../types/IBusinessCreateRequestOptions";

export default function BusinessCreationScreen() {
  const { businessId } = useParams();
  const navigate = useNavigate();
  const { isLoading, business, reload } = useBusiness();
  const [newBusiness, setNewBusiness] = React.useState<IBusiness | null>(null);
  const [error, setError] = React.useState<string>("");

  React.useEffect(() => {
    reload(businessId);
  }, [businessId, reload]);

  const handleSaveBusiness = React.useCallback(async () => {
    if (newBusiness && newBusiness.company) {
      const requestOptions: IBusinessCreateRequestOptions = {
        logo: newBusiness.logo,
        abbreviation: newBusiness.abbreviation,
        currency: newBusiness.currency,
        company: newBusiness.company,
        businessId: businessId,
        template: newBusiness.template
      };

      const result = await BusinessController.create(
        JSON.stringify(requestOptions)
      );

      if (result && result.status === "success" && result.data) {
        setError("");
        return navigate(`/businesses/${result.data.uuid}`, { replace: true });
      }

      if (result && result.message) {
        setError(result.message);
      }
    }
  }, [newBusiness, businessId]);

  return (
    <LayoutContainer
      id="rda-screen-business-viewer"
      sx={{ overflowY: "scroll" }}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: theme.spacing(1)
        })}
      >
        <Button
          startIcon={<ArrowBackIosIcon />}
          aria-label="Back to previous screen"
          onClick={() => navigate(-1)}
          variant="contained"
        >
          Back
        </Button>
        <PageTitleTypography
          variant="h1"
          sx={(theme) => ({ marginBottom: 0, marginLeft: theme.spacing(3) })}
        >
          {businessId ? "Edit" : "Create"} Business
        </PageTitleTypography>
        <Box sx={{ flex: 1 }} />
        <Button
          startIcon={<EditRoundedIcon />}
          aria-label="Edit"
          onClick={() => handleSaveBusiness()}
          variant="contained"
          color="secondary"
        >
          Save
        </Button>
      </Box>
      {businessId && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          The changes you made here will not affect previously created invoices
          and drafts. All information are locked in when an invoice or draft is
          created.
        </Alert>
      )}
      {isLoading ? (
        <LoadingBox message="loading business..." />
      ) : (
        <BusinessEditor business={business} setBusiness={setNewBusiness} />
      )}
      {businessId && !isLoading && !business && (
        <Paper elevation={0} sx={(theme) => ({ padding: theme.spacing(3) })}>
          <Typography>Business not found.</Typography>
        </Paper>
      )}
      {error && (
        <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </LayoutContainer>
  );
}
