import React from "react";
import { useAppSelector } from "../redux/hooks";
import DraftController from "../repositories/DraftController";
import { IDraftSimple } from "../types/IDraftSimple";
import { IPaginationMeta } from "../types/IPaginationMeta";

type UseDraftsResponse = {
  isLoading: boolean;
  drafts: IDraftSimple[];
  pagination?: IPaginationMeta;
  reload: (limit: number, page: number) => Promise<void>;
};

export default function useDrafts(): UseDraftsResponse {
  const selectedBusiness = useAppSelector((state) => state.business.business);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [drafts, setDrafts] = React.useState<IDraftSimple[]>([]);
  const [pagination, setPagination] = React.useState<IPaginationMeta>();

  const reload = React.useCallback(
    async (limit: number, page: number) => {
      setIsLoading(true);
      setDrafts([]);
      setPagination(undefined);
      if (selectedBusiness?.uuid) {
        const response = await DraftController.list(selectedBusiness.uuid, {
          limit,
          page
        });
        setDrafts(response.data);
        setPagination(response.meta);
      }
      setIsLoading(false);
    },
    [selectedBusiness]
  );

  return {
    isLoading,
    drafts,
    pagination,
    reload
  };
}
