import { IHttpResponse } from "../types/IHttpResponse";
import { ITax } from "../types/ITax";
import HttpController from "./HttpController";

class TaxController {
  /**
   * Represents a Business.
   * @constructor
   */
  constructor() {
    return this;
  }

  /**
   * Returns a list
   */
  static async list(): Promise<IHttpResponse<ITax[]>> {
    const url = "/api/taxes";

    try {
      const result = await HttpController.get(url, false);
      return result;
    } catch (e) {
      console.error(e);
    }

    return {
      status: "error",
      message: "Unknown error getting tax.",
      data: [],
    };
  }
}

export default TaxController;
