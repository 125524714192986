import { TableRow, TableRowProps, styled } from "@mui/material";

const StyledTableHeaderRow = styled(TableRow)<TableRowProps>(({ theme }) => ({
  background: theme.palette.primary.main,
  "& .MuiTableCell-root.MuiTableCell-head": {
    color: "#fff",
  },
  "& .MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader": {
    background: theme.palette.primary.main,
  },
}));

export default StyledTableHeaderRow;
