import React from "react";
import { useAppSelector } from "../redux/hooks";
import BusinessController from "../repositories/BusinessController";
import { IBusiness } from "../types/IBusiness";

type UseBusinessResponse = {
  business: IBusiness | null;
  isLoading: boolean;
  reload: (businessId: string | undefined) => Promise<void>;
};

export default function useBusiness(): UseBusinessResponse {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [business, setBusiness] = React.useState<IBusiness | null>(null);
  const user = useAppSelector((state) => state.auth.user);

  const reload = React.useCallback(
    async (businessId: string | undefined) => {
      setIsLoading(true);
      if (user && businessId) {
        try {
          // Check for businesses belonging to this user
          const result = await BusinessController.get(businessId);
          setBusiness(result);
        } catch (e) {
          setBusiness(null);
        }
      } else {
        setBusiness(null);
      }
      setIsLoading(false);
    },
    [user]
  );

  return React.useMemo<UseBusinessResponse>(
    () => ({
      business,
      isLoading,
      reload,
    }),
    [business, isLoading, user, reload]
  );
}
