import { Typography, styled } from "@mui/material";

interface PaperFormTitleProps {
  title?: string;
}

export default function PaperFormTitle({ title }: PaperFormTitleProps) {
  return <StyledTitle variant="h2">{title}</StyledTitle>;
}

const StyledTitle = styled(Typography)({
  width: "100%",
  textAlign: "center",
  fontSize: "2rem",
  fontWeight: "300",
}) as typeof Typography;
