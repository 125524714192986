import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListProps,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";
import { IInvoiceTemplate } from "../types/IInvoiceTemplate";

type InvoiceTemplateViewerProps = {
  template: IInvoiceTemplate;
};

export default function InvoiceTemplateViewer({
  template,
}: InvoiceTemplateViewerProps) {
  return (
    <Box>
      {template.footnote && (
        <Box sx={{ mb: 2 }}>
          <BoldTypography>Footnotes</BoldTypography>
          <StyledList>
            {template.footnote.map((note, index) => (
              <ListItem key={`note-${index}`}>
                <ListItemText>{note}</ListItemText>
              </ListItem>
            ))}
          </StyledList>
        </Box>
      )}
      {template.footer && (
        <Box sx={{ mb: 2 }}>
          <BoldTypography>Footer</BoldTypography>
          <Typography>{template.footer}</Typography>
        </Box>
      )}
    </Box>
  );
}

const BoldTypography = styled(Typography)<TypographyProps>({
  fontWeight: "700",
});

const StyledList = styled(List)<ListProps>(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
  "& li": {
    borderWidth: "0 0 2px 0",
    borderColor: theme.palette.action.hover,
    borderStyle: "solid",
  },
  "& li:last-of-type": {
    borderWidth: 0,
  },
}));
