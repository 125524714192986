import { TableCell, styled, tableCellClasses } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "@media print": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      borderTopColor: theme.palette.grey[200],
      borderTopStyle: "solid"
    }
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.875rem"
  }
}));

export default StyledTableCell;
