import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import React from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./Router";
import useFirebase from "./hooks/useFirebase";
import { useAppSelector } from "./redux/hooks";
import LoadingScreen from "./screens/LoadingScreen";
import { getThemeTokens } from "./themes/getThemeTokens";

export default function ThemedApp() {
  const { initiated } = useFirebase();
  const themeMode = useAppSelector((state) => state.theme.mode);

  window.document.title =
    "RedeuBooks, Online Invoicing Software for SME, Singapore";

  const theme = React.useMemo(
    () => createTheme(getThemeTokens(themeMode)),
    [themeMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {initiated ? <RouterProvider router={router} /> : <LoadingScreen />}
    </ThemeProvider>
  );
}
