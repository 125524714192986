import LoadingBox from "../components/atoms/LoadingBox";
import Layout from "../layouts/Layout";

export default function LoadingScreen() {
  return (
    <Layout appBar={false}>
      <LoadingBox />
    </Layout>
  );
}
