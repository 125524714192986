import React from "react";
import { useAppSelector } from "../redux/hooks";
import InvoiceController from "../repositories/InvoiceController";
import { InvoiceStatusType } from "../types/IInvoiceInfo";
import { IInvoiceSimple } from "../types/IInvoiceSimple";
import { IPaginationMeta } from "../types/IPaginationMeta";

type UseInvoicesResponse = {
  isLoading: boolean;
  invoices: IInvoiceSimple[];
  pagination?: IPaginationMeta;
  reload: (
    limit: number,
    status: InvoiceStatusType,
    page: number,
    customer_id?: string
  ) => Promise<void>;
};

export default function useInvoices(): UseInvoicesResponse {
  const selectedBusiness = useAppSelector((state) => state.business.business);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [invoices, setInvoices] = React.useState<IInvoiceSimple[]>([]);
  const [pagination, setPagination] = React.useState<IPaginationMeta>();

  const reload = React.useCallback(
    async (
      limit: number,
      status: InvoiceStatusType,
      page: number,
      customer_id?: string
    ) => {
      setIsLoading(true);
      setInvoices([]);
      setPagination(undefined);
      if (selectedBusiness?.uuid) {
        const response = await InvoiceController.list(selectedBusiness.uuid, {
          limit,
          status,
          page,
          customer_id
        });
        setInvoices(response.data);
        setPagination(response.meta);
      }
      setIsLoading(false);
    },
    [selectedBusiness]
  );

  return {
    isLoading,
    invoices,
    pagination,
    reload
  };
}
