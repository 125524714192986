import { IDraft } from "../types/IDraft";
import { IDraftSimple } from "../types/IDraftSimple";
import { IHttpResponse } from "../types/IHttpResponse";
import { IInvoice } from "../types/IInvoice";
import { IInvoiceRequestOptions } from "../types/IInvoiceRequestOptions";
import { IPaginatedResponse } from "../types/IPaginatedResponse";
import Http from "./HttpController";

class DraftController {
  /**
   * Represents an Invoice.
   * @constructor
   */
  constructor() {
    return this;
  }

  /**
   * Returns a list of drafts
   */
  static async list(
    business_id: string,
    options: IInvoiceRequestOptions
  ): Promise<IPaginatedResponse<IDraftSimple>> {
    const searchParam = new URLSearchParams({
      limit: `${options.limit}`,
      page: `${options.page}`,
    });

    const url = `/api/businesses/${business_id}/drafts?${searchParam.toString()}`;

    try {
      const result = await Http.get(url, false);
      return result;
    } catch (e) {
      console.error(e);
    }

    return {
      status: "error",
      data: [],
    };
  }

  /**
   * Approves a drafted invoice
   */
  static async approve(business_id: string, invoice_id: string) {
    const url = `/api/businesses/${business_id}/drafts/${invoice_id}`;

    try {
      const result = await Http.post(url, "");
      return result;
    } catch (e) {
      console.error(e);
    }

    return null;
  }

  /**
   * Returns a single drafted invoice
   */
  static async get(
    business_id: string,
    invoice_id: string
  ): Promise<IDraft | null> {
    const url = `/api/businesses/${business_id}/drafts/${invoice_id}`;

    try {
      const result = await Http.get(url);
      return result;
    } catch (e) {
      console.error(e);
    }

    return null;
  }

  /**
   * Creates a single invoice
   */
  static async create(
    business_id: string,
    content: string
  ): Promise<IHttpResponse<IInvoice> | null> {
    const url = `/api/businesses/${business_id}/invoices`;

    try {
      const result = await Http.post<IHttpResponse<IInvoice>>(
        url,
        content,
        false
      );
      return result;
    } catch (e) {
      console.error(e);
    }

    return null;
  }

  /**
   * Delete a drafted invoice, cannot delete submitted invoice
   */
  static async delete(business_id: string, invoice_id: string) {
    const url = `/api/businesses/${business_id}/drafts/${invoice_id}`;

    try {
      const result = await Http.delete(url);
      return result;
    } catch (e) {
      console.error(e);
    }

    return null;
  }
}

export default DraftController;
