const app = {
  app_title: "RedeuBooks",
  app_subtitle: "Online Invoicing System for SME (closed Beta)",
  api_url:
    process.env.NODE_ENV === "production"
      ? "https://api.redeubooks.com/v1"
      : "https://redeubooks-api.test/v1",
  api_key: "FXrywj0ELltfJtfVv8Zj2RpQLYud1emc",
  firebase_config:
    process.env.NODE_ENV === "production"
      ? {
          apiKey: "AIzaSyA5VExyLtIwGI3XG6U1-FAr8TF-E7t2fHM",
          authDomain: "redooor-identity.firebaseapp.com",
          projectId: "redooor-identity",
          storageBucket: "redooor-identity.appspot.com",
          messagingSenderId: "727785257814",
          appId: "1:727785257814:web:a9e2881bbace99e60f34f7"
        }
      : {
          apiKey: "AIzaSyAQcFhoAL4h-F0vj6j7SoXjv8X8O-Xu52U",
          authDomain: "redooor-accounting.firebaseapp.com",
          projectId: "redooor-accounting",
          storageBucket: "redooor-accounting.appspot.com",
          messagingSenderId: "41838538431",
          appId: "1:41838538431:web:859817dd647cb55ad8a00a",
          measurementId: "G-6MN298WJQW"
        }
};

export default app;
