import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import {
  Box,
  IconButton,
  ListItem,
  Typography,
  TypographyProps,
  styled
} from "@mui/material";
import React from "react";
import getAmountInCurrency from "../../../helpers/getAmountInCurrency";
import getShortLocalDateFromString from "../../../helpers/getShortLocalDateFromString";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { RouterListItemButton } from "../../../styledMuiComponents";
import { IInvoiceSimple } from "../../../types/IInvoiceSimple";

type InvoiceListItemProps = {
  invoice: IInvoiceSimple;
  onClickActionButton: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    i: IInvoiceSimple
  ) => void;
};

export default function InvoiceListItem({
  invoice,
  onClickActionButton
}: InvoiceListItemProps) {
  const { width } = useWindowDimensions();

  return (
    <ListItem
      id={`invoice-list-${invoice.uuid}`}
      className="rdr-invoice-list-item"
      disablePadding
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="More actions"
          color="secondary"
          onClick={(e) => onClickActionButton(e, invoice)}
        >
          <MoreVertRoundedIcon />
        </IconButton>
      }
      alignItems="flex-start"
    >
      <RouterListItemButton
        to={`/invoices/${invoice.uuid}/view`}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          p: 3
        }}
      >
        <Typography variant="caption" component="span">
          {getShortLocalDateFromString(invoice.invoice.invoice_date)} #
          {invoice.invoice.number}
        </Typography>
        <StyledBillToText
          component="span"
          sx={{
            maxWidth: width * 0.6
          }}
        >
          {invoice.bill_to.company.name ?? "Unknown Bill-to"}
        </StyledBillToText>
        <Typography component="span" style={{ display: "block" }}>
          {invoice.grand_total
            ? getAmountInCurrency(invoice.invoice.currency, invoice.grand_total)
            : "-"}
        </Typography>
        <Box sx={{ flexDirection: "row" }}>
          <StyledStatusBadgeText
            variant="caption"
            className={invoice.invoice.status}
          >
            {invoice.invoice.status}
          </StyledStatusBadgeText>
          <StyledStatusBadgeText
            variant="caption"
            className={invoice.invoice.status}
          >
            Due on {getShortLocalDateFromString(invoice.invoice.due_date)}
          </StyledStatusBadgeText>
        </Box>
      </RouterListItemButton>
    </ListItem>
  );
}

const StyledBillToText = styled(Typography)<TypographyProps>({
  fontWeight: "bold",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
});

const StyledStatusBadgeText = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    fontSize: 10,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0.4),
    borderRadius: theme.spacing(0.2),
    marginRight: theme.spacing(0.4),
    "&.OUTSTANDING": {
      backgroundColor: theme.palette.error.main
    },
    "&.PAID": {
      backgroundColor: theme.palette.success.main
    },
    "&.PARTIAL": {
      backgroundColor: theme.palette.info.main
    }
  })
);
