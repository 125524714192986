import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableProps,
  TableRow,
  styled
} from "@mui/material";
import getAmountInCurrency from "../helpers/getAmountInCurrency";
import { StyledTableCell, StyledTableRow } from "../styledMuiComponents";
import { IJournal } from "../types/IJournal";
import { ITax } from "../types/ITax";

type JournalsPrintViewProps = {
  journals: IJournal[];
  taxes: ITax[];
  currency: string;
  subtotal: string;
  total_tax?: string;
  grand_total: string;
};

export default function JournalsPrintView({
  journals,
  taxes,
  currency,
  subtotal,
  total_tax,
  grand_total
}: JournalsPrintViewProps) {
  return (
    <Box component="div" id="rda-journals-table">
      <PrintTable size="small" aria-label="Journals table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Item</StyledTableCell>
            <StyledTableCell width="10%">Quantity</StyledTableCell>
            <StyledTableCell width="10%">Unit Price</StyledTableCell>
            <StyledTableCell align="left" width="12%">
              Amount
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {journals.map((journal) => (
            <StyledTableRow key={journal.uuid}>
              <StyledTableCell component="th" scope="row">
                <b>{journal.document.name}</b>
                <br />
                {journal.document.description}
              </StyledTableCell>
              <StyledTableCell>{journal.document.quantity}</StyledTableCell>
              <StyledTableCell>
                {getAmountInCurrency(
                  journal.currency,
                  journal.document.unit_price
                )}
              </StyledTableCell>
              <StyledTableCell align="left">
                {getAmountInCurrency(
                  journal.currency,
                  Number(journal.document.unit_price) *
                    Number(journal.document.quantity)
                )}
              </StyledTableCell>
            </StyledTableRow>
          ))}
          {subtotal && total_tax && (
            <StyledTableRow>
              <StyledTableCell colSpan={3} align="right">
                Subtotal:
              </StyledTableCell>
              <StyledTableCell align="left">
                {getAmountInCurrency(currency, subtotal)}
              </StyledTableCell>
            </StyledTableRow>
          )}
          {total_tax && (
            <StyledTableRow>
              <StyledTableCell colSpan={3} align="right">
                {taxes.length > 0 ? taxes[0].name : "Tax Amount"}:
              </StyledTableCell>
              <StyledTableCell align="left">
                {getAmountInCurrency(currency, total_tax)}
              </StyledTableCell>
            </StyledTableRow>
          )}
          <StyledTableRow>
            <StyledTableCell colSpan={3} align="right">
              <b>Grand Total:</b>
            </StyledTableCell>
            <StyledTableCell align="left">
              <b>{getAmountInCurrency(currency, grand_total)}</b>
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </PrintTable>
    </Box>
  );
}

const PrintTable = styled(Table)<TableProps>({
  width: "100%",
  "@media print": {
    pageBreakInside: "auto",
    pageBreakAfter: "auto",
    "& tr": {
      pageBreakInside: "avoid",
      pageBreakAfter: "auto"
    },
    "& th": {
      pageBreakInside: "avoid",
      pageBreakAfter: "auto"
    },
    "& td": {
      pageBreakInside: "avoid",
      pageBreakAfter: "auto"
    },
    "& thead": {
      display: "table-header-group"
    },
    "& tfoot": {
      display: "table-header-group"
    }
  }
});
