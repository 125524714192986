import styled from "@emotion/styled";
import {
  Autocomplete,
  Box,
  BoxProps,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import { DateField, DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { IPaymentTerm } from "../types/IPaymentTerm";
import { ITax } from "../types/ITax";
import CurrencySelector from "./CurrencySelector";
import TaxSelector from "./TaxSelector";

type InvoiceFormProps = {
  invoiceDate: Dayjs;
  paymentTerms: IPaymentTerm[];
  currency: string | null;
  setCurrency: (c: string | null) => void;
  dueDate: Dayjs | null;
  setDueDate: (dd: Dayjs | null) => void;
  purchaseOrder: string;
  setPurchaseOrder: (po: string) => void;
  paymentTerm: IPaymentTerm | null;
  setPaymentTerm: (pt: IPaymentTerm | null) => void;
  tax: ITax | null;
  setTax: (t: ITax | null) => void;
  taxes: ITax[];
  loadingTaxes: boolean;
};

export default function InvoiceForm({
  invoiceDate,
  paymentTerms,
  currency,
  setCurrency,
  dueDate,
  setDueDate,
  purchaseOrder,
  setPurchaseOrder,
  paymentTerm,
  setPaymentTerm,
  tax,
  setTax,
  taxes,
  loadingTaxes
}: InvoiceFormProps) {
  return (
    <StyledContainer>
      <Typography
        variant="h3"
        sx={{ fontSize: 20, fontWeight: "700", mb: 2, flex: 1 }}
      >
        INVOICE
      </Typography>
      <Grid container spacing={2}>
        <Grid item lg={6} xs={12}>
          <TextField
            fullWidth
            id="inputInvoice"
            label="Invoice No"
            variant="outlined"
            disabled={true}
            size="small"
            value="Auto-generated"
            sx={{ flex: 1 }}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <DateField
            fullWidth
            label="Invoice Date"
            value={invoiceDate}
            disabled={true}
            sx={{ flex: 1 }}
            size="small"
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <CurrencySelector
            selectedCode={currency}
            onChange={(newCurrency) => setCurrency(newCurrency)}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <DatePicker
            label="Due Date"
            value={dueDate}
            onChange={(newValue, context) => {
              if (!context.validationError) {
                setDueDate(newValue);
              }
            }}
            closeOnSelect
            minDate={dayjs()}
            slotProps={{ textField: { size: "small", fullWidth: true } }}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <TextField
            fullWidth
            id="inputPurchaseOrder"
            label="Purchase Order (Optional)"
            variant="outlined"
            value={purchaseOrder}
            onChange={(e) => setPurchaseOrder(e.target.value)}
            size="small"
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <Autocomplete
            disablePortal
            id="paymentTermSelector"
            value={paymentTerm}
            options={paymentTerms}
            onChange={(e, value) => setPaymentTerm(value)}
            getOptionLabel={(option) => option.name}
            fullWidth
            size="small"
            renderInput={(params) => (
              <TextField {...params} label="Payment Term" />
            )}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <TaxSelector
            fullWidth
            size="small"
            selectedTax={tax}
            onChange={(newTax) => setTax(newTax)}
            taxes={taxes}
            loading={loadingTaxes}
          />
        </Grid>
      </Grid>
    </StyledContainer>
  );
}

const StyledContainer = styled(Box)<BoxProps>({
  display: "flex",
  flexDirection: "column"
});
