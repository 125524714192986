import {
  Box,
  BoxProps,
  styled,
  SxProps,
  Table,
  TableBody,
  TableBodyProps,
  TableCell,
  tableCellClasses,
  TableCellProps,
  TableHead,
  TableHeadProps,
  TableProps,
  TableRow,
  TableRowProps,
  Theme,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React from "react";
import IResponsiveTableColumn from "./types/IResponsiveTableColumn";
import IResponsiveTableHeader from "./types/IResponsiveTableHeader";
import IResponsiveTableRow from "./types/IResponsiveTableRow";

type ResponsiveTableProps = {
  id?: string;
  headers: IResponsiveTableHeader[];
  rows: IResponsiveTableRow[];
  ariaLabel?: string;
  sx?: SxProps<Theme>;
  stickyHeader?: boolean;
  children?: React.ReactNode;
};

export default function ResponsiveTable({
  id,
  headers,
  rows,
  ariaLabel,
  sx = [],
  stickyHeader = false,
  children
}: ResponsiveTableProps) {
  const theme = useTheme();
  const isLargerScreens = useMediaQuery(theme.breakpoints.up("md"));

  const displayMobileTitle = React.useCallback(
    (col: IResponsiveTableColumn) => {
      const _header = headers.find((h) => h.index === col.headerIdx);
      return (
        <ResponsiveMobileHeadBox component="span" className="header">
          {_header?.label ?? "Unknown"}
        </ResponsiveMobileHeadBox>
      );
    },
    [headers]
  );

  return (
    <ResponsiveStyledTable
      id={id}
      sx={sx}
      aria-label={ariaLabel}
      stickyHeader={stickyHeader}
    >
      <ResponsiveTableHead>
        <TableRow>
          {headers.map((h) => (
            <ResponsiveTableHeadCell
              key={`${id}-header-${h.index}`}
              sx={h.lgSx}
            >
              {h.label}
            </ResponsiveTableHeadCell>
          ))}
        </TableRow>
      </ResponsiveTableHead>
      <ResponsiveTableBody>
        {rows.map((row) => (
          <ResponsiveTableBodyRow key={row.uuid}>
            {row.columns.map((col) => (
              <React.Fragment key={col.uuid}>
                <ResponsiveTableBodyCell
                  component="td"
                  scope="row"
                  sx={isLargerScreens ? col.lgSx : col.smSx}
                >
                  {displayMobileTitle(col)}
                  <Box component="span" className="label">
                    {col.label}
                  </Box>
                </ResponsiveTableBodyCell>
              </React.Fragment>
            ))}
          </ResponsiveTableBodyRow>
        ))}
        {children}
      </ResponsiveTableBody>
    </ResponsiveStyledTable>
  );
}

const ResponsiveStyledTable = styled(Table)<TableProps>(() => ({
  tableLayout: "auto",
  width: "100%"
}));

const ResponsiveTableHeadCell = styled(TableCell)<TableCellProps>(
  ({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white
    }
  })
);

const ResponsiveMobileHeadBox = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    display: "none"
  }
}));

const ResponsiveTableHead = styled(TableHead)<TableHeadProps>(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none"
  }
}));

const ResponsiveTableBody = styled(TableBody)<TableBodyProps>(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "block"
  }
}));

const ResponsiveTableBodyRow = styled(TableRow)<TableRowProps>(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    padding: theme.spacing(3)
  },
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
    "@media print": {
      backgroundColor: theme.palette.common.white
    }
  },
  "&:last-of-type td": {
    borderBottom: "0px !important"
  }
}));

const ResponsiveTableBodyCell = styled(TableCell)<TableCellProps>(
  ({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
      border: 0,
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
      gap: theme.spacing(1),
      "& .header": {
        color: theme.palette.grey[500]
      },
      "& .label": {
        flex: 1,
        textAlign: "right"
      }
    }
  })
);
