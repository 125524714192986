import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  Theme,
} from "@mui/material";
import React from "react";
import { InvoiceStatusType } from "../types/IInvoiceInfo";

type InvoiceStatusSelectorProps = {
  invoiceStatus: string;
  onChangeStatus: (value: InvoiceStatusType) => void;
  size?: "small" | "medium";
  sx?: SxProps<Theme>;
};

export default function InvoiceStatusSelector({
  invoiceStatus,
  onChangeStatus,
  size = "small",
  sx = [],
}: InvoiceStatusSelectorProps) {
  const [selected, setSelected] = React.useState<string>(invoiceStatus);

  const handleSetSelected = (value: InvoiceStatusType) => {
    setSelected(value);
    onChangeStatus(value);
  };

  return (
    <FormControl size={size} sx={sx} color="secondary">
      <InputLabel id="rda-invoices-select-status-label">
        Filtered Status
      </InputLabel>
      <Select
        labelId="rda-invoices-select-status-label"
        id="rda-invoices-select-status"
        value={selected}
        label="Filtered Status"
        onChange={(event: SelectChangeEvent) => {
          handleSetSelected(event.target.value as InvoiceStatusType);
        }}
      >
        <MenuItem value="ALL">All</MenuItem>
        <MenuItem value="OUTSTANDING">Outstanding</MenuItem>
        <MenuItem value="PARTIAL">Partially Paid</MenuItem>
        <MenuItem value="PAID">Fully Paid</MenuItem>
      </Select>
    </FormControl>
  );
}
