import { IHttpResponse } from "../types/IHttpResponse";
import { IInvoice } from "../types/IInvoice";
import { IInvoiceRequestOptions } from "../types/IInvoiceRequestOptions";
import { IInvoiceSimple } from "../types/IInvoiceSimple";
import { IPaginatedResponse } from "../types/IPaginatedResponse";
import HttpController from "./HttpController";

class InvoiceController {
  /**
   * Represents an Invoice.
   * @constructor
   */
  constructor() {
    return this;
  }

  /**
   * Returns a list
   */
  static async list(
    business_id: string,
    options: IInvoiceRequestOptions
  ): Promise<IPaginatedResponse<IInvoiceSimple>> {
    const searchParam = new URLSearchParams({
      limit: `${options.limit}`,
      status: `${options.status}`,
      page: `${options.page}`,
    });

    if (options.customer_id) {
      searchParam.append("customer_id", `${options.customer_id}`);
    }

    const url = `/api/businesses/${business_id}/invoices?${searchParam.toString()}`;

    try {
      const result = await HttpController.get(url, false);
      return result;
    } catch (e) {
      console.error(e);
    }

    return {
      status: "error",
      data: [],
    };
  }

  /**
   * Returns a single invoice
   */
  static async get(
    business_id: string,
    invoice_id: string
  ): Promise<IInvoice | null> {
    const url = `/api/businesses/${business_id}/invoices/${invoice_id}`;

    try {
      const result = await HttpController.get(url);
      return result;
    } catch (e) {
      console.error(e);
    }

    return null;
  }

  /**
   * Returns a single invoice for print with public link
   */
  static async getPublicLink(
    public_link: string,
    invoiceNumber: string
  ): Promise<IHttpResponse<IInvoice | null>> {
    const url = `/open/api/invoices/${public_link}/print/${invoiceNumber}`;

    try {
      const result = await HttpController.getPublic(url, false);
      return result;
    } catch (e) {
      console.error(e);
    }

    return {
      status: "error",
      message: "Unknown error occurred.",
    };
  }

  /**
   * Check if user has permission to create invoice
   */
  static async hasPermission(): Promise<IHttpResponse<string>> {
    const url = "/api/permissions/check/invoice/create";

    try {
      const result = await HttpController.get(url, false);
      return result;
    } catch (e) {
      console.error(e);
    }

    return {
      status: "error",
      message: "Unknown error with checking permission.",
      support: "Uknown error occured from server.",
    };
  }
}

export default InvoiceController;
