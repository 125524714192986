import { Typography, TypographyProps, styled } from "@mui/material";

const GradientTypography = styled(Typography)<TypographyProps>({
  fontFamily: "'Chilanka', Roboto, sans-serif",
  fontSize: "1.5rem",
  fontWeight: "400",
  background: "linear-gradient(to right, #dacdc6, #ac8135, #53796f)",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  color: "rgba(255,255,255,0.3)",
  marginTop: "0.5rem" // Font is somewhat higher than other fonts, push it downwards
}) as typeof Typography;

export default GradientTypography;
