import React, { useMemo } from "react";
import { useAppSelector } from "../redux/hooks";
import TaxController from "../repositories/TaxController";
import { ITax } from "../types/ITax";

type UseTaxesResponse = {
  loading: boolean;
  taxes: ITax[];
  error?: string;
  reload: () => Promise<void>;
};

export default function useTaxes(): UseTaxesResponse {
  const auth = useAppSelector((state) => state.auth.user);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [taxes, setTaxes] = React.useState<ITax[]>([]);
  const [error, setError] = React.useState<string>("");

  const reload = React.useCallback(async () => {
    setLoading(true);
    const result = await TaxController.list();
    if (result.status === "error") {
      setError(result.message);
      setTaxes([]);
    } else {
      setError("");
      setTaxes(result.data ?? []);
    }
    setLoading(false);
  }, []);

  // On mount, load taxes
  React.useEffect(() => {
    if (auth) {
      reload();
    }
  }, [auth]);

  const memoedValue: UseTaxesResponse = useMemo(
    () => ({
      loading,
      taxes,
      error,
      reload,
    }),
    [loading, error, taxes, reload]
  );

  return memoedValue;
}
