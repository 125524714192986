import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableProps,
  TableRow,
  styled
} from "@mui/material";
import { grey } from "@mui/material/colors";
import getAmountInCurrency from "../helpers/getAmountInCurrency";
import getLocalDateFromString from "../helpers/getLocalDateFromString";
import getShortLocalDateFromString from "../helpers/getShortLocalDateFromString";
import { StyledTableCell, StyledTableRow } from "../styledMuiComponents";
import { IPayment } from "../types/IPayment";

type PaymentsPrintViewProps = {
  payments: IPayment[];
};

const PaymentsPrintView = ({ payments }: PaymentsPrintViewProps) => {
  return (
    <Box component="div" id="rda-payments-table">
      <PrintTable size="small" aria-label="Payments table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Amount</StyledTableCell>
            <StyledTableCell>Payment Date</StyledTableCell>
            <StyledTableCell>Method</StyledTableCell>
            <StyledTableCell>Notes</StyledTableCell>
            <StyledTableCell>Added on</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.map((payment) => (
            <StyledTableRow key={payment.uuid}>
              <StyledTableCell component="th" scope="row">
                {getAmountInCurrency(payment.currency, payment.amount)}
              </StyledTableCell>
              <StyledTableCell>
                {getShortLocalDateFromString(payment.datetime)}
              </StyledTableCell>
              <StyledTableCell>{payment.document.method}</StyledTableCell>
              <StyledTableCell>{payment.document.note}</StyledTableCell>
              <StyledTableCell>
                {getLocalDateFromString(payment.created_at)}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </PrintTable>
    </Box>
  );
};

export default PaymentsPrintView;

const PrintTable = styled(Table)<TableProps>({
  width: "100%",
  "@media print": {
    pageBreakInside: "auto",
    pageBreakAfter: "auto",
    "& tr": {
      pageBreakInside: "avoid",
      pageBreakAfter: "auto",
      "&:last-of-type": {
        borderBottomStyle: "solid",
        borderBottomColor: grey[300],
        borderBottomWidth: "1px"
      }
    },
    "& th": {
      pageBreakInside: "avoid",
      pageBreakAfter: "auto"
    },
    "& td": {
      pageBreakInside: "avoid",
      pageBreakAfter: "auto"
    },
    "& thead": {
      display: "table-header-group"
    },
    "& tfoot": {
      display: "table-header-group"
    }
  }
});
