import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import {
  ButtonGroup,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem
} from "@mui/material";
import React from "react";
import { GradientButton, StyledButton } from "../../../styledMuiComponents";
import { IDraftSimple } from "../../../types/IDraftSimple";
import LinkRouterButton from "../../LinkRouterButton";

type DraftTableActionsProps = {
  draft: IDraftSimple;
  onDeleteDraft: (draftId: string) => void;
};

export default function DraftTableActions({
  draft,
  onDeleteDraft
}: DraftTableActionsProps) {
  // Handle menu click
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        size="small"
        aria-label="Action buttons group"
      >
        <GradientButton
          to={`/drafts/${draft.uuid}/view`}
          component={LinkRouterButton}
        >
          View
        </GradientButton>
        <StyledButton onClick={handleOpenMenu}>
          <MoreVertRoundedIcon fontSize="small" />
        </StyledButton>
      </ButtonGroup>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "draft-action-button"
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <MenuItem onClick={() => onDeleteDraft(draft.uuid)}>
          <ListItemIcon>
            <DeleteRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Delete" secondary="Remove Permenantly" />
        </MenuItem>
      </Menu>
    </>
  );
}
