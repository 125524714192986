import {
  Box,
  BoxProps,
  SxProps,
  Theme,
  Typography,
  styled
} from "@mui/material";
import React from "react";

type GridRowDescriptionProps = {
  label: string;
  description: string;
  labelProps?: BoxProps;
  descriptionProps?: BoxProps;
  labelSx?: SxProps<Theme>;
  descriptionSx?: SxProps<Theme>;
};

export default function GridRowDescription({
  label,
  description,
  labelProps,
  descriptionProps,
  labelSx = [],
  descriptionSx = []
}: GridRowDescriptionProps) {
  if (description) {
    return (
      <React.Fragment>
        <LabelBox sx={labelContainerSx} {...labelProps}>
          <Typography
            sx={[
              labelLocalSx,
              ...(Array.isArray(labelSx) ? labelSx : [labelSx])
            ]}
          >
            {label}
          </Typography>
        </LabelBox>
        <DescriptionBox sx={descriptionContainerSx} {...descriptionProps}>
          <Typography sx={colonSx}>:</Typography>
          <Typography
            sx={[
              TextOverflowSx,
              ...(Array.isArray(descriptionSx)
                ? descriptionSx
                : [descriptionSx])
            ]}
          >
            {description}
          </Typography>
        </DescriptionBox>
      </React.Fragment>
    );
  }

  return null;
}

const LabelBox = styled(Box)<BoxProps>({
  gridColumnStart: 1
});

const DescriptionBox = styled(Box)<BoxProps>({
  gridColumnStart: 2
});

const TextOverflowSx: SxProps<Theme> = {
  width: "100%",
  whiteSpace: "pre-wrap",
  fontSize: "0.875rem",
  wordBreak: "break-word"
};

const labelContainerSx: SxProps<Theme> = {
  display: "flex",
  flexDirection: "row"
};

const descriptionContainerSx: SxProps<Theme> = {
  display: "flex",
  flexDirection: "row"
};

const labelLocalSx: SxProps<Theme> = {
  display: "flex",
  flex: 1,
  fontWeight: "700",
  fontSize: "0.875rem",
  wordBreak: "break-word"
};

const colonSx: SxProps<Theme> = (theme) => ({
  display: "flex",
  fontWeight: "700",
  paddingLeft: theme.spacing(0),
  paddingRight: theme.spacing(1)
});
