import {
  Autocomplete,
  Box,
  BoxProps,
  CircularProgress,
  SxProps,
  TextField,
  Theme,
  styled,
} from "@mui/material";
import React from "react";
import { ICustomerSimple } from "../types/ICustomerSimple";

type CustomerSearchFormProps = {
  selectedCustomer: ICustomerSimple | null;
  customers: ICustomerSimple[];
  onChange: (cust: ICustomerSimple | null) => void;
  sx?: SxProps<Theme>;
  size?: "small" | "medium";
  isLoading?: boolean;
};

export default function CustomerSearchForm({
  selectedCustomer,
  customers,
  onChange,
  sx = [],
  size = "small",
  isLoading = true,
}: CustomerSearchFormProps) {
  return (
    <CustomerFormContainer sx={sx}>
      <Autocomplete
        disablePortal
        id="rdr-customer-search-form"
        value={selectedCustomer}
        onChange={(e, value) => onChange(value)}
        options={customers}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
        fullWidth
        size={size}
        loading={isLoading}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search by Customer"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </CustomerFormContainer>
  );
}

const CustomerFormContainer = styled(Box)<BoxProps>({
  display: "flex",
  flexDirection: "row",
});
