import React from "react";
import CustomerController from "../repositories/CustomerController";
import { ICustomer } from "../types/ICustomer";

type UseCustomersResponse = {
  isLoading: boolean;
  customer: ICustomer | null;
  getCustomer: (businessId: string, customerId: string) => void;
  clear: () => void;
  error?: string;
};

export default function useCustomer(): UseCustomersResponse {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [customer, setCustomer] = React.useState<ICustomer | null>(null);
  const [error, setError] = React.useState<string>();

  const getCustomer = React.useCallback(
    (businessId: string, customerId: string) => {
      setIsLoading(true);
      setCustomer(null);
      setError(undefined);
      if (businessId) {
        CustomerController.get(businessId, customerId)
          .then((result) => {
            setCustomer(result);
            setError(undefined);
          })
          .catch(() => {
            setCustomer(null);
            setError("Failed to fetch customers.");
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    []
  );

  const clear = () => {
    setIsLoading(true);
    setCustomer(null);
    setError(undefined);
  };

  React.useEffect(() => {
    return () => {
      clear();
    };
  }, []);

  const memoedValues: UseCustomersResponse = React.useMemo(
    () => ({
      isLoading,
      customer,
      error,
      getCustomer,
      clear
    }),
    [isLoading, customer, error, getCustomer]
  );

  return memoedValues;
}
