import { Box, SxProps, Theme } from "@mui/material";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import PaperError from "../components/PaperError";
import Layout from "../layouts/Layout";

export default function ErrorPageScreen() {
  const error = useRouteError();

  return (
    <Layout appBar={true}>
      <Box id="error-page" sx={containerSx}>
        {isRouteErrorResponse(error) ? (
          <PaperError
            title={`Oops! ${error.status}`}
            subtitle="Sorry, an unexpected error has occurred:"
            bodyTitle={error.statusText}
            bodyContent={error.data?.message}
          />
        ) : error instanceof Error ? (
          <PaperError
            title="Oops! Error"
            subtitle="Sorry, an unexpected error has occurred:"
            bodyContent={error.message}
          />
        ) : (
          <PaperError
            title="Oops! Error"
            subtitle="Sorry, an unexpected error has occurred:"
            bodyContent="The error is unclear."
          />
        )}
      </Box>
    </Layout>
  );
}

const containerSx: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
  padding: "20px",
  overflowY: "scroll",
};
