import { useEffect, useState } from "react";

function getWindowDimensions(): IUseWindowDimensionsResult {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

interface IUseWindowDimensionsResult {
  width: number;
  height: number;
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] =
    useState<IUseWindowDimensionsResult>(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
