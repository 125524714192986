import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import EmptyBoxedMessage from "../components/atoms/EmptyBoxedMessage";
import InvoiceTable from "../components/organisms/InvoiceTable";
import TitledPageTemplate from "../components/templates/TitledPageTemplate";
import useInvoices from "../hooks/useInvoices";
import { useAppSelector } from "../redux/hooks";
import InvoiceController from "../repositories/InvoiceController";

export default function DashboardScreen() {
  const navigate = useNavigate();
  const selectedBusiness = useAppSelector((state) => state.business.business);
  const loadingBusiness = useAppSelector((state) => state.business.isLoading);
  const { isLoading, invoices, pagination, reload } = useInvoices();
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(5);
  const [hasPermission, setHasPermission] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");

  React.useEffect(() => {
    InvoiceController.hasPermission()
      .then((permission) => {
        setHasPermission(permission.status === "success");
        setError(permission.message);
      })
      .catch(() => {
        setHasPermission(false);
        setError("Unknown error occured when checking permission.");
      });
  }, [isLoading]);

  React.useEffect(() => {
    reload(rowsPerPage, "OUTSTANDING", page); // Get 5 outstanding invoices
  }, [selectedBusiness, page, rowsPerPage]);

  return (
    <TitledPageTemplate
      containerId="rda-screen-dashboard"
      title="Dashboard"
      isLoading={isLoading || loadingBusiness}
      isLoadingMessage="Loading dashboard..."
    >
      {selectedBusiness && !isLoading && (
        <InvoiceTable
          invoices={invoices}
          title="Outstanding invoices"
          pagination={pagination}
          onChangePage={(p: number) => setPage(p)}
          onChangeRowsPerPage={(perPage: number) => setRowsPerPage(perPage)}
          error={error}
          onClickAction={() => navigate("/invoices/create")}
          disabledAction={!hasPermission}
        />
      )}
      {!isLoading && !loadingBusiness && !selectedBusiness && (
        <EmptyBoxedMessage
          title="No Business Found"
          message="You can start by creating a new business."
          action={
            <Button
              startIcon={<AddRoundedIcon />}
              aria-label="Add a new business"
              onClick={() => navigate("/businesses")}
              variant="contained"
              color="secondary"
              sx={{ whiteSpace: "nowrap" }}
            >
              Add Business
            </Button>
          }
        />
      )}
      {!isLoading &&
        !loadingBusiness &&
        selectedBusiness &&
        invoices.length === 0 && (
          <EmptyBoxedMessage
            title="No Invoice Found"
            message="You can start by creating a new invoice."
            action={
              <Button
                startIcon={<AddRoundedIcon />}
                aria-label="Add a new invoice"
                onClick={() => navigate("/invoices/create")}
                variant="contained"
                color="secondary"
                sx={{ whiteSpace: "nowrap" }}
              >
                Add Invoice
              </Button>
            }
          />
        )}
    </TitledPageTemplate>
  );
}
