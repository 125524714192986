import {
  Box,
  Divider,
  List,
  ListItemText,
  Toolbar,
  Typography
} from "@mui/material";
import React from "react";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import GradientBackground from "../layouts/GradientBackground";
import { Image } from "../redeuComponents";
import { useAppSelector } from "../redux/hooks";
import { RouterListItemButton } from "../styledMuiComponents";
import LinkRouterButton from "./LinkRouterButton";

type SideBarProps = {
  onDismiss?: () => void;
};

export default function SideBar({ onDismiss }: SideBarProps) {
  const location = useLocation();
  const biz_company = useAppSelector(
    (state) => state.business.business?.company
  );

  const checkMatch = (pathExpression: string): boolean => {
    const match = matchPath(location.pathname, pathExpression);

    return match ? true : false;
  };

  const handleDismiss = React.useCallback(() => {
    if (onDismiss) {
      onDismiss();
    }
  }, [onDismiss]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <Toolbar sx={(theme) => ({ background: theme.palette.primary.dark })}>
        <Image
          src={require("../images/redeubooks-logo.png")}
          width={150}
          height={30}
          fit="contain"
        />
      </Toolbar>
      <Divider />
      {biz_company && (
        <GradientBackground
          sx={(theme) => ({ flex: "none", padding: theme.spacing(2) })}
        >
          <Typography sx={(theme) => ({ color: theme.palette.common.white })}>
            {biz_company.name}
          </Typography>
        </GradientBackground>
      )}
      <List>
        <RouterListItemButton
          to="/dashboard"
          selected={checkMatch("/dashboard")}
          LinkComponent={LinkRouterButton}
          onClick={handleDismiss}
        >
          <ListItemText primary="Dashboard" />
        </RouterListItemButton>
        <RouterListItemButton
          to="/invoices/create"
          selected={checkMatch("/invoices/create")}
          LinkComponent={LinkRouterButton}
          onClick={handleDismiss}
        >
          <ListItemText primary="Create Invoice" />
        </RouterListItemButton>
        <RouterListItemButton
          to="/invoices"
          selected={checkMatch("/invoices")}
          LinkComponent={LinkRouterButton}
          onClick={handleDismiss}
        >
          <ListItemText primary="Invoices" />
        </RouterListItemButton>
        <RouterListItemButton
          to="/drafts"
          selected={checkMatch("/drafts")}
          LinkComponent={LinkRouterButton}
          onClick={handleDismiss}
        >
          <ListItemText primary="Drafts" />
        </RouterListItemButton>
        <RouterListItemButton
          to="/journals"
          selected={checkMatch("/journals")}
          LinkComponent={LinkRouterButton}
          onClick={handleDismiss}
        >
          <ListItemText primary="Sales & Receivable" />
        </RouterListItemButton>
      </List>
      <Box sx={{ flex: 1 }} />
      <Divider />
      <Box sx={(theme) => ({ padding: theme.spacing(2) })}>
        <Typography sx={{ fontSize: 12 }}>
          &copy;Copyright since 2021, Redooor LLP. <br />
          All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
}
