import {
  Box,
  BoxProps,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
  styled
} from "@mui/material";
import React from "react";
import { ICompany } from "../types/ICompany";

type CompanyEditorProps = {
  company: ICompany | null;
  setCompany: (cp: ICompany | null) => void;
  sx?: SxProps<Theme>;
};

export default function CompanyEditor({
  company,
  setCompany,
  sx
}: CompanyEditorProps) {
  const [name, setName] = React.useState("");
  const [uen, setUen] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [email, setEmail] = React.useState("");
  // Address
  const [line1, setLine1] = React.useState("");
  const [line2, setLine2] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [zipcode, setZipcode] = React.useState("");

  React.useEffect(() => {
    if (company) {
      setName(company.name ?? "");
      setUen(company.uen ?? "");
      setPhone(company.phone ?? "");
      setMobile(company.mobile ?? "");
      setWebsite(company.website ?? "");
      setEmail(company.email ?? "");
      // Address
      if (company.address) {
        setLine1(company.address.line1 ?? "");
        setLine2(company.address.line2 ?? "");
        setCity(company.address.city ?? "");
        setState(company.address.state ?? "");
        setCountry(company.address.country ?? "");
        setZipcode(company.address.zipcode ?? "");
      }
    }
  }, [company]);

  React.useEffect(() => {
    setCompany({
      name: name,
      uen: uen,
      address: {
        line1: line1,
        line2: line2,
        city: city,
        state: state,
        country: country,
        zipcode: zipcode
      },
      phone: phone,
      mobile: mobile,
      website: website,
      email: email
    });
  }, [
    name,
    uen,
    phone,
    mobile,
    website,
    email,
    line1,
    line2,
    city,
    state,
    country,
    zipcode
  ]);

  return (
    <StyledContainer sx={sx}>
      <CustomInput
        fullWidth
        id="inputName"
        label="Company Name"
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
      />
      <CustomInput
        fullWidth
        id="inputUen"
        label="UEN"
        value={uen}
        onChange={(e) => {
          setUen(e.target.value);
        }}
      />
      <CustomInput
        fullWidth
        id="inputLine1"
        label="Address Line 1"
        value={line1}
        onChange={(e) => {
          setLine1(e.target.value);
        }}
      />
      <CustomInput
        fullWidth
        id="inputLine2"
        label="Address Line 2"
        value={line2}
        onChange={(e) => {
          setLine2(e.target.value);
        }}
      />
      <CustomInput
        fullWidth
        id="inputCity"
        label="City"
        value={city}
        onChange={(e) => {
          setCity(e.target.value);
        }}
      />
      <CustomInput
        fullWidth
        id="inputState"
        label="State"
        value={state}
        onChange={(e) => {
          setState(e.target.value);
        }}
      />
      <CustomInput
        fullWidth
        id="inputCountry"
        label="Country"
        value={country}
        onChange={(e) => {
          setCountry(e.target.value);
        }}
      />
      <CustomInput
        fullWidth
        id="inputZipcode"
        label="Zip Code"
        value={zipcode}
        onChange={(e) => {
          setZipcode(e.target.value);
        }}
      />
      <CustomInput
        fullWidth
        id="inputPhone"
        label="Phone"
        value={phone}
        onChange={(e) => {
          setPhone(e.target.value);
        }}
      />
      <CustomInput
        fullWidth
        id="inputMobile"
        label="Mobile"
        value={mobile}
        onChange={(e) => {
          setMobile(e.target.value);
        }}
      />
      <CustomInput
        fullWidth
        id="inputWebsite"
        label="Website"
        value={website}
        onChange={(e) => {
          setWebsite(e.target.value);
        }}
      />
      <CustomInput
        fullWidth
        id="inputEmail"
        label="Email"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
    </StyledContainer>
  );
}

const StyledContainer = styled(Box)<BoxProps>(() => ({
  display: "flex",
  flexDirection: "column"
}));

const CustomInput = styled(TextField)<TextFieldProps>(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));
