import {
  Autocomplete,
  Box,
  BoxProps,
  Button,
  CircularProgress,
  SxProps,
  TextField,
  Theme,
  styled
} from "@mui/material";
import React from "react";
import { ICustomerSimple } from "../types/ICustomerSimple";

type BilltoSearchFormProps = {
  selectedCustomer: ICustomerSimple | null;
  customers: ICustomerSimple[];
  onChange: (cust: ICustomerSimple | null) => void;
  actionButtonLabel?: string;
  onActionButtonClick?: () => void;
  sx?: SxProps<Theme>;
  size?: "small" | "medium";
  isLoading?: boolean;
};

export default function BilltoSearchForm({
  selectedCustomer,
  customers,
  onChange,
  sx = [],
  size = "small",
  isLoading = true,
  actionButtonLabel,
  onActionButtonClick
}: BilltoSearchFormProps) {
  return (
    <BilltoFormContainer id="billto-search-form" sx={sx}>
      <Autocomplete
        disablePortal
        id="rdr-billto-form"
        value={selectedCustomer}
        onChange={(e, value) => onChange(value)}
        options={customers}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
        fullWidth
        size={size}
        loading={isLoading}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Bill to"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
      />
      {actionButtonLabel && onActionButtonClick && !selectedCustomer && (
        <ButtonWrapper>
          <Button
            variant="contained"
            sx={{ width: "120px", whiteSpace: "nowrap" }}
            onClick={onActionButtonClick}
          >
            {actionButtonLabel}
          </Button>
        </ButtonWrapper>
      )}
    </BilltoFormContainer>
  );
}

const BilltoFormContainer = styled(Box)<BoxProps>({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: "0.5rem"
});

const ButtonWrapper = styled(Box)<BoxProps>({
  display: "flex",
  flex: 1,
  justifyContent: "flex-end"
});
