import AddRoundedIcon from "@mui/icons-material/AddRounded";
import {
  Alert,
  Box,
  Button,
  Fab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React from "react";
import {
  StyledTableHeaderRow,
  StyledTablePagination
} from "../../../styledMuiComponents";
import { IInvoiceSimple } from "../../../types/IInvoiceSimple";
import { IPaginationMeta } from "../../../types/IPaginationMeta";
import ResponsivePagination from "../../atoms/ResponsivePagination";
import TablePaginationActions from "../../atoms/TablePaginationActions";
import HeaderContainerWithFilter from "../../HeaderContainerWithFilter";
import InvoiceList from "../InvoiceList";
import InvoiceTableRow from "./InvoiceTableRow";

type InvoiceTableProps = {
  title?: string;
  invoices: IInvoiceSimple[];
  pagination?: IPaginationMeta;
  selector?: React.ReactNode;
  error?: string;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (rowsPerPage: number) => void;
  onClickAction?: () => void;
  disabledAction?: boolean;
};

export default function InvoiceTable({
  invoices,
  title,
  pagination,
  selector,
  error,
  onChangePage,
  onChangeRowsPerPage,
  onClickAction,
  disabledAction
}: InvoiceTableProps) {
  const theme = useTheme();
  const isLargerScreens = useMediaQuery(theme.breakpoints.up("sm"));

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    onChangePage(newPage + 1); // Pagination UI is 0 index, but server starts from 1.
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onChangeRowsPerPage(parseInt(event.target.value, 10));
    onChangePage(1); // Pagination UI is 0 index, but server starts from 1.
  };

  return (
    <React.Fragment>
      <HeaderContainerWithFilter title={title}>
        {selector}
        {error ? (
          <Alert
            id="permission-error-alert"
            severity="error"
            variant="filled"
            sx={{ p: 0.2, pl: 3, pr: 3, flex: 1 }}
          >
            {error}
          </Alert>
        ) : (
          <Box sx={{ flex: 1 }} />
        )}
        {onClickAction && isLargerScreens && (
          <Button
            color="secondary"
            variant="contained"
            onClick={onClickAction}
            disabled={disabledAction}
          >
            Create New
          </Button>
        )}
        {onClickAction && !isLargerScreens && (
          <Fab
            color="default"
            aria-label="Create New Invoice"
            onClick={onClickAction}
            disabled={disabledAction}
            sx={{
              position: "fixed",
              bottom: theme.spacing(2),
              right: theme.spacing(2)
            }}
          >
            <AddRoundedIcon />
          </Fab>
        )}
      </HeaderContainerWithFilter>
      {invoices?.length > 0 && (
        <React.Fragment>
          {isLargerScreens ? (
            <Paper variant="outlined" sx={{ overflow: "hidden" }}>
              <TableContainer>
                <Table stickyHeader>
                  <TableHead>
                    <StyledTableHeaderRow>
                      <TableCell>Customer</TableCell>
                      <TableCell>Invoice No.</TableCell>
                      <TableCell>Invoice Date</TableCell>
                      <TableCell>Due date</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Amount Paid</TableCell>
                      <TableCell>Actions</TableCell>
                    </StyledTableHeaderRow>
                  </TableHead>
                  <TableBody>
                    {invoices.map((invoice) => (
                      <InvoiceTableRow key={invoice.uuid} invoice={invoice} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {pagination && (
                <StyledTablePagination
                  id="rdr-invoice-table-pagination"
                  component={Paper}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  colSpan={7}
                  count={pagination.total}
                  rowsPerPage={pagination.per_page}
                  page={pagination.current_page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              )}
            </Paper>
          ) : (
            <Paper
              variant="outlined"
              sx={{
                overflow: "hidden"
              }}
            >
              <InvoiceList invoices={invoices} />
              {pagination && (
                <ResponsivePagination
                  pagination={pagination}
                  onChangePage={onChangePage}
                />
              )}
            </Paper>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
