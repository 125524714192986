import { Box, List, ListProps, styled } from "@mui/material";
import React from "react";
import { IInvoiceSimple } from "../../../types/IInvoiceSimple";
import InvoiceListItem from "./InvoiceListItem";
import InvoiceListItemMenu from "./InvoiceListItemMenu";

type InvoiceListProps = {
  invoices: IInvoiceSimple[];
};

export default function InvoiceList({ invoices }: InvoiceListProps) {
  // Handle menu click
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedMenu, setSelectedMenu] = React.useState<IInvoiceSimple | null>(
    null
  );

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          bgcolor: "background.paper",
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5
        }}
      >
        <StyledList id="rdr-invoice-list">
          {invoices.map((i) => (
            <InvoiceListItem
              key={`invoice-list-${i.uuid}`}
              invoice={i}
              onClickActionButton={(e, id) => {
                setSelectedMenu(id);
                handleOpenMenu(e);
              }}
            />
          ))}
        </StyledList>
      </Box>
      {selectedMenu && (
        <InvoiceListItemMenu
          anchorEl={anchorEl}
          onClose={handleClose}
          selectedMenu={selectedMenu}
        />
      )}
    </React.Fragment>
  );
}

const StyledList = styled(List)<ListProps>(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 0,
  "& .rdr-invoice-list-item": {
    "&:nth-of-type(odd)": {
      backgroundColor: `${theme.palette.action.hover}`
    }
  }
}));
