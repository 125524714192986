import {
  Box,
  BoxProps,
  Link,
  styled,
  SxProps,
  Theme,
  Typography,
  TypographyProps
} from "@mui/material";
import React from "react";

type ListItemProps = {
  label?: string | React.ReactNode;
  description?: string | React.ReactNode;
  labelProps?: BoxProps;
  labelTextSx?: SxProps<Theme>;
  descriptionProps?: BoxProps;
  descriptionTextSx?: SxProps<Theme>;
  isLink?: boolean;
  sx?: SxProps<Theme>;
};

export default function ListItem({
  label,
  description,
  labelProps,
  labelTextSx = [],
  descriptionProps,
  descriptionTextSx = [],
  isLink = false,
  sx = []
}: ListItemProps) {
  return (
    <Box
      sx={[
        (theme) => ({
          display: "flex",
          justifyContent: "space-between",
          columnGap: theme.spacing(1),
          borderBottom: "1px dashed transparent"
        }),
        // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      {label && (
        <LabelBox {...labelProps}>
          <ResponsiveTypography
            sx={[
              (theme) => ({ color: theme.palette.grey[500] }),
              ...(Array.isArray(labelTextSx) ? labelTextSx : [labelTextSx])
            ]}
          >
            {label}
          </ResponsiveTypography>
        </LabelBox>
      )}
      {description && (
        <DescriptionBox {...descriptionProps}>
          {isLink && typeof description === "string" ? (
            <Link
              href={description}
              component="a"
              target="_blank"
              rel="noopener"
              color="inherit"
              variant="inherit"
              sx={descriptionTextSx}
            >
              {description}
            </Link>
          ) : (
            <ResponsiveTypography sx={descriptionTextSx}>
              {description}
            </ResponsiveTypography>
          )}
        </DescriptionBox>
      )}
    </Box>
  );
}

const LabelBox = styled(Box)<BoxProps>(({ theme }) => ({
  fontSize: "0.875rem",
  lineHeight: "1.43",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
    lineHeight: "1.43"
  }
}));

const DescriptionBox = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  textAlign: "right",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
    lineHeight: "1.43"
  }
}));

const ResponsiveTypography = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
      lineHeight: "1.43"
    }
  })
);
