import React from "react";
import CustomerController from "../repositories/CustomerController";

type UseCustomersResponse<T> = {
  isLoading: boolean;
  customers: T[];
  reload: (businessId: string) => void;
  error?: string;
};

export default function useCustomers<T>(
  limit = 100,
  simple = 0
): UseCustomersResponse<T> {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [customers, setCustomers] = React.useState<T[]>([]);
  const [error, setError] = React.useState<string>();

  const reload = React.useCallback((businessId: string) => {
    setIsLoading(true);
    setCustomers([]);
    setError(undefined);
    if (businessId) {
      CustomerController.list(businessId, limit, simple)
        .then((list) => {
          setCustomers(list);
          setError(undefined);
        })
        .catch(() => {
          setCustomers([]);
          setError("Failed to fetch customers.");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  React.useEffect(() => {
    return () => {
      setIsLoading(true);
      setCustomers([]);
      setError(undefined);
    };
  }, []);

  const memoedValues: UseCustomersResponse<T> = React.useMemo(
    () => ({
      isLoading,
      customers,
      error,
      reload
    }),
    [isLoading, customers, error, reload]
  );

  return memoedValues;
}
