import { SxProps, TableCell, Theme } from "@mui/material";

type StyledResponsiveTableRowTitleProps = {
  title: React.ReactNode;
  sx?: SxProps<Theme>;
};

const StyledResponsiveTableRowTitle = ({
  title,
  sx
}: StyledResponsiveTableRowTitleProps) => (
  <TableCell
    sx={sx}
    className={["small-screen-row-title", title ? "" : "hidden"].join(" ")}
  >
    {title}
  </TableCell>
);

export default StyledResponsiveTableRowTitle;
