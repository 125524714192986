import { TablePagination, TablePaginationProps, styled } from "@mui/material";

const StyledTablePagination = styled(TablePagination)<TablePaginationProps>(
  ({ theme }) => ({
    overflow: "visible",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white
  })
);

export default StyledTablePagination;
