import { IJournal } from "../types/IJournal";
import { IJournalRequestOptions } from "../types/IJournalRequestOptions";
import { IPaginatedResponse } from "../types/IPaginatedResponse";
import Http from "./HttpController";

class JournalController {
  /**
   * Represents a Journal.
   * @constructor
   */
  constructor() {
    return this;
  }

  /**
   * Returns a list of journals, for this business in specified date range
   */
  static async list(
    business_id: string,
    options: IJournalRequestOptions
  ): Promise<IPaginatedResponse<IJournal>> {
    const searchParam = new URLSearchParams({
      limit: `${options.limit}`,
      page: `${options.page}`,
    });

    if (options.start_date) {
      searchParam.append("start_date", `${options.start_date}`);
    }

    if (options.end_date) {
      searchParam.append("end_date", `${options.end_date}`);
    }

    const url = `/api/businesses/${business_id}/journals?${searchParam.toString()}`;

    try {
      const result = await Http.get(url, false);
      return result;
    } catch (e) {
      console.error(e);
    }

    return {
      status: "error",
      data: [],
    };
  }
}

export default JournalController;
