import { Box, Paper, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import getAmountInCurrency from "../../../helpers/getAmountInCurrency";
import getLocalDateFromString from "../../../helpers/getLocalDateFromString";
import getShortLocalDateFromString from "../../../helpers/getShortLocalDateFromString";
import { StyledTablePagination } from "../../../styledMuiComponents";
import { IDraftSimple } from "../../../types/IDraftSimple";
import { IPaginationMeta } from "../../../types/IPaginationMeta";
import HeaderContainerWithFilter from "../../HeaderContainerWithFilter";
import ResponsivePagination from "../../atoms/ResponsivePagination";
import ResponsiveTable from "../../atoms/ResponsiveTable";
import IResponsiveTableColumn from "../../atoms/ResponsiveTable/types/IResponsiveTableColumn";
import IResponsiveTableHeader from "../../atoms/ResponsiveTable/types/IResponsiveTableHeader";
import IResponsiveTableRow from "../../atoms/ResponsiveTable/types/IResponsiveTableRow";
import TablePaginationActions from "../../atoms/TablePaginationActions";
import DraftTableActions from "./DraftTableActions";

type DraftTableProps = {
  title?: string;
  drafts: IDraftSimple[];
  pagination?: IPaginationMeta;
  selector?: React.ReactNode;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (rowsPerPage: number) => void;
  onDeleteDraft: (draftId: string) => void;
};

export const DRAFT_TABLE_HEADERS: IResponsiveTableHeader[] = [
  {
    index: "header-customer",
    label: "Customer"
  },
  {
    index: "header-invoice-date",
    label: "Invoice Date"
  },
  {
    index: "header-due-date",
    label: "Due Date"
  },
  {
    index: "header-amount",
    label: "Amount"
  },
  {
    index: "header-created-at",
    label: "Created At"
  },
  {
    index: "header-actions",
    label: "Actions"
  }
];

export default function DraftTable({
  drafts,
  title,
  pagination,
  selector,
  onChangePage,
  onChangeRowsPerPage,
  onDeleteDraft
}: DraftTableProps) {
  const theme = useTheme();
  const isLargerScreens = useMediaQuery(theme.breakpoints.up("sm"));

  const handleChangePage = (newPage: number) => {
    onChangePage(newPage + 1); // Pagination UI is 0 index, but server starts from 1.
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onChangeRowsPerPage(parseInt(event.target.value, 10));
    onChangePage(1); // Pagination UI is 0 index, but server starts from 1.
  };

  const draftRows: IResponsiveTableRow[] = React.useMemo(() => {
    const _rows: IResponsiveTableRow[] = [];
    if (drafts && drafts.length > 0) {
      drafts.map((draft) => {
        const cols: IResponsiveTableColumn[] = [
          {
            headerIdx: "header-customer",
            uuid: `${draft.uuid}-name`,
            label: draft.bill_to.company.name
          },
          {
            headerIdx: "header-invoice-date",
            uuid: `${draft.uuid}-invoice-date`,
            label: getShortLocalDateFromString(draft.invoice.invoice_date)
          },
          {
            headerIdx: "header-due-date",
            uuid: `${draft.uuid}-due-date`,
            label: getShortLocalDateFromString(draft.invoice.due_date)
          },
          {
            headerIdx: "header-amount",
            uuid: `${draft.uuid}-amount`,
            label:
              draft.grand_total && draft.invoice.currency
                ? getAmountInCurrency(draft.invoice.currency, draft.grand_total)
                : "-"
          },
          {
            headerIdx: "header-created-at",
            uuid: `${draft.uuid}-created-at`,
            label: getLocalDateFromString(draft.created_at)
          },
          {
            headerIdx: "header-actions",
            uuid: `${draft.uuid}-actions`,
            label: (
              <DraftTableActions draft={draft} onDeleteDraft={onDeleteDraft} />
            )
          }
        ];
        const row: IResponsiveTableRow = {
          uuid: draft.uuid,
          columns: cols
        };
        _rows.push(row);
      });
    }

    return _rows;
  }, [drafts]);

  return (
    <React.Fragment>
      <HeaderContainerWithFilter title={title}>
        {selector && (
          <React.Fragment>
            <Box sx={{ flex: 1 }} />
            {selector}
          </React.Fragment>
        )}
      </HeaderContainerWithFilter>
      {drafts?.length > 0 && (
        <Paper variant="outlined" sx={{ overflow: "hidden" }}>
          <ResponsiveTable headers={DRAFT_TABLE_HEADERS} rows={draftRows} />
          {pagination && isLargerScreens && (
            <StyledTablePagination
              component={Paper}
              rowsPerPageOptions={[5, 10, 25, 50]}
              colSpan={7}
              count={pagination.total}
              rowsPerPage={pagination.per_page}
              page={pagination.current_page - 1}
              onPageChange={(_e, p) => handleChangePage(p)}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          )}
          {pagination && !isLargerScreens && (
            <ResponsivePagination
              pagination={pagination}
              onChangePage={handleChangePage}
            />
          )}
        </Paper>
      )}
    </React.Fragment>
  );
}
