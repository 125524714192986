import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Alert, Box, Button, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import EmptyBoxedMessage from "../components/atoms/EmptyBoxedMessage";
import BusinessTable from "../components/organisms/BusinessTable";
import TitledPageTemplate from "../components/templates/TitledPageTemplate";
import useBusinesses from "../hooks/useBusinesses";
import { useAppSelector } from "../redux/hooks";
import BusinessController from "../repositories/BusinessController";

export default function BusinessesScreen() {
  const theme = useTheme();
  const isLargerScreens = useMediaQuery(theme.breakpoints.up("sm"));
  const navigate = useNavigate();
  const selectedBusiness = useAppSelector((state) => state.business.business);
  const { isLoading, businesses, pagination, reload } = useBusinesses();
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(25);
  const [hasPermission, setHasPermission] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");

  React.useEffect(() => {
    BusinessController.hasPermission()
      .then((permission) => {
        setHasPermission(permission.status === "success");
        setError(permission.message);
      })
      .catch(() => {
        setHasPermission(false);
        setError("Unknown error occured when checking permission.");
      });
  }, [isLoading]);

  React.useEffect(() => {
    // selectedBusiness is necessary for refreshing if the selected business changes,
    // such as when the page reload.
    reload(rowsPerPage, page); // Get invoices
  }, [page, rowsPerPage, selectedBusiness]);

  return (
    <TitledPageTemplate
      containerId="rda-screen-businesses"
      title="Settings"
      isLoading={isLoading}
      isLoadingMessage="Loading businesses..."
    >
      {!isLoading && businesses.length > 0 && (
        <BusinessTable
          businesses={businesses}
          title="Businesses"
          pagination={pagination}
          onChangePage={(p: number) => setPage(p)}
          onChangeRowsPerPage={(perPage: number) => setRowsPerPage(perPage)}
          selector={
            <Box
              sx={{
                display: "flex",
                flexDirection: isLargerScreens ? "row" : "column",
                flex: 1,
                justifyContent: "flex-end",
                gap: 2
              }}
            >
              {error && (
                <Alert
                  id="permission-error-alert"
                  severity="error"
                  variant="filled"
                  sx={{ p: 0, pl: 3, pr: 3 }}
                >
                  {error}
                </Alert>
              )}
              <Button
                startIcon={<AddCircleOutlineRoundedIcon />}
                aria-label="Add Business"
                onClick={() => navigate("/businesses/create")}
                variant="contained"
                color="secondary"
                disabled={!hasPermission}
              >
                Add
              </Button>
            </Box>
          }
        />
      )}
      {!isLoading && businesses.length === 0 && (
        <EmptyBoxedMessage
          title="No Business Found"
          message="You can start by creating a new business."
          action={
            <Button
              startIcon={<AddRoundedIcon />}
              aria-label="Add a new business"
              onClick={() => navigate("/businesses/create")}
              variant="contained"
              color="secondary"
              sx={{ whiteSpace: "nowrap" }}
            >
              Add Business
            </Button>
          }
        />
      )}
    </TitledPageTemplate>
  );
}
