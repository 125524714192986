import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { Button } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import DraftPageTemplate from "../components/templates/DraftPageTemplate";
import useSingleDraft from "../hooks/useSingleDraft";
import { useAppSelector } from "../redux/hooks";
import DraftController from "../repositories/DraftController";

export default function DraftSingleScreen() {
  const { invoiceId } = useParams();
  const { isLoading, draft, reload } = useSingleDraft();
  const [isApproving, setIsApproving] = React.useState<boolean>(false);
  const selectedBusiness = useAppSelector((state) => state.business.business);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (invoiceId) {
      reload(invoiceId); // Get invoice
    }
  }, [invoiceId, reload]);

  const handleApproveDraft = React.useCallback(async () => {
    if (selectedBusiness?.uuid && invoiceId) {
      setIsApproving(true);
      const result = await DraftController.approve(
        selectedBusiness.uuid,
        invoiceId
      );
      setIsApproving(false);
      if (result) {
        return navigate(`/invoices/${invoiceId}/view`, { replace: true });
      }
    }
  }, [invoiceId, selectedBusiness]);

  return (
    <DraftPageTemplate
      title="Draft Invoice"
      draft={draft}
      onClickBack={() => navigate(-1)}
      isLoading={isLoading}
      isEmpty={!isLoading && !draft}
      endHeaderAction={
        !isLoading &&
        draft && (
          <Button
            color="secondary"
            variant="contained"
            startIcon={<CheckCircleOutlineRoundedIcon />}
            onClick={() => handleApproveDraft()}
            disabled={isApproving}
          >
            APPROVE
          </Button>
        )
      }
      isEmptyAction={
        <Button
          startIcon={<AddRoundedIcon />}
          aria-label="Add a new invoice"
          onClick={() => navigate("/invoices/create")}
          variant="contained"
          color="secondary"
          sx={{ whiteSpace: "nowrap" }}
        >
          Add Invoice
        </Button>
      }
    />
  );
}
