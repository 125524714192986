import { Paper, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import getLocalDateFromString from "../../../helpers/getLocalDateFromString";
import {
  GradientButton,
  StyledTablePagination
} from "../../../styledMuiComponents";
import { IBusiness } from "../../../types/IBusiness";
import { IPaginationMeta } from "../../../types/IPaginationMeta";
import ResponsivePagination from "../../atoms/ResponsivePagination";
import ResponsiveTable from "../../atoms/ResponsiveTable";
import IResponsiveTableColumn from "../../atoms/ResponsiveTable/types/IResponsiveTableColumn";
import IResponsiveTableHeader from "../../atoms/ResponsiveTable/types/IResponsiveTableHeader";
import IResponsiveTableRow from "../../atoms/ResponsiveTable/types/IResponsiveTableRow";
import TablePaginationActions from "../../atoms/TablePaginationActions";
import HeaderContainerWithFilter from "../../HeaderContainerWithFilter";
import LinkRouterButton from "../../LinkRouterButton";

type BusinessTableProps = {
  title?: string;
  businesses: IBusiness[];
  pagination?: IPaginationMeta;
  selector?: React.ReactNode;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (rowsPerPage: number) => void;
};

const BUSINESS_TABLE_HEADERS: IResponsiveTableHeader[] = [
  {
    index: "header-name",
    label: "Name"
  },
  {
    index: "header-business-currency",
    label: "Business Currency"
  },
  {
    index: "header-abbreviation",
    label: "Abbreviation"
  },
  {
    index: "header-created-at",
    label: "Created At"
  },
  {
    index: "header-updated-at",
    label: "Updated At"
  },
  {
    index: "header-actions",
    label: "Actions"
  }
];

export default function BusinessTable({
  businesses,
  title,
  pagination,
  selector,
  onChangePage,
  onChangeRowsPerPage
}: BusinessTableProps) {
  const theme = useTheme();
  const isLargerScreens = useMediaQuery(theme.breakpoints.up("sm"));

  const handleChangePage = (newPage: number) => {
    onChangePage(newPage + 1); // Pagination UI is 0 index, but server starts from 1.
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onChangeRowsPerPage(parseInt(event.target.value, 10));
    onChangePage(1); // Pagination UI is 0 index, but server starts from 1.
  };

  const businessRows: IResponsiveTableRow[] = React.useMemo(() => {
    const _rows: IResponsiveTableRow[] = [];
    if (businesses && businesses.length > 0) {
      businesses.map((business) => {
        const cols: IResponsiveTableColumn[] = [
          {
            headerIdx: "header-name",
            uuid: `${business.uuid}-header-name`,
            label: business.company.name
          },
          {
            headerIdx: "header-business-currency",
            uuid: `${business.uuid}-header-business-currency`,
            label: business.currency ?? "Not specified (default SGD)"
          },
          {
            headerIdx: "header-abbreviation",
            uuid: `${business.uuid}-header-abbreviation`,
            label: business.abbreviation ?? "Not specified"
          },
          {
            headerIdx: "header-created-at",
            uuid: `${business.uuid}-header-created-at`,
            label:
              business.created_at && getLocalDateFromString(business.created_at)
          },
          {
            headerIdx: "header-updated-at",
            uuid: `${business.uuid}-header-updated-at`,
            label:
              business.updated_at && getLocalDateFromString(business.updated_at)
          },
          {
            headerIdx: "header-actions",
            uuid: `${business.uuid}-header-actions`,
            label: (
              <GradientButton
                to={`/businesses/${business.uuid}`}
                component={LinkRouterButton}
              >
                View
              </GradientButton>
            )
          }
        ];
        const row: IResponsiveTableRow = {
          uuid: business.uuid ?? "-",
          columns: cols
        };
        _rows.push(row);
      });
    }

    return _rows;
  }, [businesses]);

  return (
    <React.Fragment>
      <HeaderContainerWithFilter title={title}>
        {selector}
      </HeaderContainerWithFilter>
      {businesses?.length > 0 && (
        <React.Fragment>
          <Paper variant="outlined" sx={{ overflow: "hidden" }}>
            <ResponsiveTable
              headers={BUSINESS_TABLE_HEADERS}
              rows={businessRows}
              stickyHeader={true}
            />
            {pagination && isLargerScreens && (
              <StyledTablePagination
                component={Paper}
                rowsPerPageOptions={[5, 10, 25, 50]}
                colSpan={7}
                count={pagination.total}
                rowsPerPage={pagination.per_page}
                page={pagination.current_page - 1}
                onPageChange={(_e, p) => handleChangePage(p)}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            )}
            {pagination && !isLargerScreens && (
              <ResponsivePagination
                pagination={pagination}
                onChangePage={handleChangePage}
              />
            )}
          </Paper>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
