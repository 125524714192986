import "firebase/auth";
import firebase from "firebase/compat/app";
import app_config from "../config/app";

class HttpController {
  /**
   * Represents a Content.
   * @constructor
   */
  constructor() {
    return this;
  }

  /**
   * Call HTTP Get method
   * @param {string} url - URL of API call
   * @param {boolean} dataOnly - True to return only data (default), False to return everything
   */
  static async get(url: string, dataOnly = true) {
    // Fixed a bug where auth() does not return a valid token after
    // staying on the screen for too long
    const user = firebase.auth().currentUser;
    if (!user) {
      return [];
    }

    const idToken = await user.getIdToken();
    let errorMessage = "";

    try {
      const response = await fetch(app_config.api_url + url, {
        method: "GET",
        headers: {
          "x-api-key": app_config.api_key,
          Authorization: "Bearer " + idToken
        }
      });

      // Check if returned type is json
      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        throw new TypeError("Returned data is not in JSON format.");
      }

      const result = await response.json();

      if (result.status === "success") {
        return dataOnly ? result.data : result;
      } else {
        // console.error("Error:", result.message);
        errorMessage = result.message;
      }
    } catch (error) {
      // console.error("Error:", error);
      errorMessage = "Unknown server error.";
    }

    if (dataOnly) {
      return null;
    }

    return {
      status: "error",
      message: errorMessage
    };
  }

  /**
   * Call HTTP Get method, without user token
   * @param {string} url - URL of API call
   * @param {boolean} dataOnly - True to return only data (default), False to return everything
   */
  static async getPublic(url: string, dataOnly = true) {
    let errorMessage = "";

    try {
      const response = await fetch(app_config.api_url + url, {
        method: "GET",
        headers: {
          "x-api-key": app_config.api_key
        }
      });

      // Check if returned type is json
      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        throw new TypeError("Returned data is not in JSON format.");
      }

      const result = await response.json();

      if (result.status === "success") {
        return dataOnly ? result.data : result;
      } else {
        // console.error("Error:", result.message);
        errorMessage = result.message;
      }
    } catch (error) {
      // console.error("Error:", error);
      errorMessage = "Unknown server error.";
    }

    if (dataOnly) {
      return null;
    }

    return {
      status: "error",
      message: errorMessage
    };
  }

  /**
   * Call HTTP Post method
   * @param {string} url - URL of API call
   * @param {string} content - Body of the post call, must be pre stringified
   * @param {boolean} dataOnly - True to return only data (default), False to return everything
   */
  static async post<T>(
    url: string,
    content?: string,
    dataOnly = true
  ): Promise<T | null> {
    // Fixed a bug where auth() does not return a valid token after
    // staying on the screen for too long
    const user = firebase.auth().currentUser;
    if (!user) {
      throw new TypeError("Authentication error. Please try to login again.");
    }

    const idToken = await user.getIdToken();

    if (!idToken || idToken.length === 0) {
      throw new TypeError("Authentication error. Please try to login again.");
    }

    let errorMessage = "";

    try {
      const response = await fetch(app_config.api_url + url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": app_config.api_key,
          Authorization: "Bearer " + idToken
        },
        body: content
      });

      // Check if returned type is json
      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        throw new TypeError("Returned data is not in JSON format.");
      }

      const result = await response.json();

      if (result.status === "success") {
        return dataOnly ? result.data : result;
      } else {
        console.error("Error:", result.message);
        errorMessage = result.message;
      }
    } catch (error) {
      console.error("Error:", error);
      errorMessage = error as string;
    }

    if (dataOnly) {
      return null;
    }

    return {
      status: "error",
      message: errorMessage
    } as T;
  }

  /**
   * Call HTTP Delete method
   * @param {string} url - URL of API call
   * @param {string} content - Body of the post call, must be pre stringified
   */
  static async delete(url: string, content?: string) {
    // Fixed a bug where auth() does not return a valid token after
    // staying on the screen for too long
    const user = firebase.auth().currentUser;
    if (!user) {
      throw new TypeError("Authentication error. Please try to login again.");
    }

    const idToken = await user.getIdToken();

    if (!idToken || idToken.length === 0) {
      throw new TypeError("Authentication error. Please try to login again.");
    }

    try {
      const response = await fetch(app_config.api_url + url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": app_config.api_key,
          Authorization: "Bearer " + idToken
        },
        body: content
      });

      // Check if returned type is json
      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        throw new TypeError("Returned data is not in JSON format.");
      }

      const result = await response.json();

      if (result.status === "success") {
        return result.data;
      } else {
        console.error("Error:", result.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }

    return null;
  }
}

export default HttpController;
