import { ICustomer } from "../types/ICustomer";
import Http from "./HttpController";

class CustomerController {
  /**
   * Represents a Customer.
   * @constructor
   */
  constructor() {
    return this;
  }

  /**
   * Returns a list of customers, for this business in the current year
   */
  static async list(business_id: string, limit: number, simple = 0) {
    const searchParam = new URLSearchParams({
      limit: `${limit}`,
      simple: `${simple}`
    });

    const url = `/api/businesses/${business_id}/customers?${searchParam.toString()}`;

    try {
      const result = await Http.get(url);
      return result;
    } catch (e) {
      console.error(e);
    }

    return [];
  }

  /**
   * Returns a customer using uuid
   */
  static async get(
    business_id: string,
    customer_id: string
  ): Promise<ICustomer | null> {
    const url = `/api/businesses/${business_id}/customers/${customer_id}`;

    try {
      const result = await Http.get(url);
      return result;
    } catch (e) {
      console.error(e);
    }

    return null;
  }
}

export default CustomerController;
