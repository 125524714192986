import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, IconButton, Paper, SxProps, Theme } from "@mui/material";
import React from "react";
import {
  LayoutContainer,
  PageTitleTypography
} from "../../../styledMuiComponents";
import LoadingBox from "../../atoms/LoadingBox";

type TitledPageTemplateProps = {
  containerId?: string;
  containerSx?: SxProps<Theme>;
  title: string;
  children?: React.ReactNode;
  startHeaderAction?: React.ReactNode;
  endHeaderAction?: React.ReactNode;
  isLoading?: boolean;
  isLoadingMessage?: string;
  onClickBack?: () => void;
};

export default function TitledPageTemplate({
  containerId = "rda-titled-page",
  containerSx = [],
  title,
  startHeaderAction,
  endHeaderAction,
  onClickBack,
  isLoading = false,
  isLoadingMessage = "Loading...",
  children
}: TitledPageTemplateProps) {
  return (
    <LayoutContainer id={containerId} sx={containerSx}>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: theme.spacing(2),
          flexWrap: "wrap",
          gap: "0.5rem"
        })}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {onClickBack && (
            <IconButton
              size="small"
              onClick={onClickBack}
              aria-label="Back to previous screen"
            >
              <ArrowBackIosIcon />
            </IconButton>
          )}
          <PageTitleTypography
            variant="h1"
            sx={(theme) => ({
              marginBottom: 0,
              mr: theme.spacing(1),
              fontSize: "1.6rem"
            })}
          >
            {title}
          </PageTitleTypography>
        </Box>
        {startHeaderAction}
        <Box sx={{ flex: 1 }} />
        {endHeaderAction}
      </Box>
      {isLoading ? (
        <Paper
          elevation={0}
          sx={{ flex: 1, display: "flex", border: "1px dashed #ddd" }}
        >
          <LoadingBox message={isLoadingMessage} />
        </Paper>
      ) : (
        children
      )}
    </LayoutContainer>
  );
}
