import {
  Box,
  BoxProps,
  Pagination,
  styled,
  Typography,
  TypographyProps
} from "@mui/material";
import { IPaginationMeta } from "../../../types/IPaginationMeta";

type ResponsivePaginationProps = {
  pagination: IPaginationMeta;
  onChangePage: (newPage: number) => void;
};

export default function ResponsivePagination({
  pagination,
  onChangePage
}: ResponsivePaginationProps) {
  return (
    <BoxWrapper>
      <Pagination
        size="large"
        boundaryCount={1}
        hideNextButton
        hidePrevButton
        count={Math.ceil(pagination.total / pagination.per_page)}
        page={pagination.current_page}
        onChange={(e, p) => onChangePage(p)}
        sx={{ p: 1 }}
      />
      <RecordWrapperBox>
        <RecordText variant="caption">
          Total {pagination.total} records
        </RecordText>
      </RecordWrapperBox>
    </BoxWrapper>
  );
}

const BoxWrapper = styled(Box)<BoxProps>({
  borderTop: "2px solid #f5f5f5"
});

const RecordWrapperBox = styled(Box)<BoxProps>(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  backgroundColor: theme.palette.primary.light
}));

const RecordText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.common.white
}));
