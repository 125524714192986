import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import firebase from "firebase/compat/app";

interface AuthState {
  user: firebase.User | null;
  isLoading: boolean;
}

const initialAuthState: AuthState = {
  user: null,
  isLoading: true,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    loaded: (state) => {
      state.isLoading = false;
    },
    loading: (state) => {
      state.isLoading = true;
    },
    signIn: (state, action: PayloadAction<firebase.User>) => {
      state.user = action.payload;
    },
    signOut: (state) => {
      state.user = null;
    },
  },
});

export const { loaded, loading, signIn, signOut } = authSlice.actions;

export default authSlice.reducer;
