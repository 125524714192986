import { Box, BoxProps, styled } from "@mui/material";
import * as React from "react";
import AppNavBar from "../components/AppNavBar";
import { useAppSelector } from "../redux/hooks";

type LayoutProps = {
  appBar?: boolean;
  children: React.ReactNode;
};

export default function Layout({ appBar = false, children }: LayoutProps) {
  const themeMode = useAppSelector((state) => state.theme.mode);

  return (
    <ContainerBox
      sx={{ flexDirection: "column" }}
      className={
        themeMode === "dark" ? "rda-dark-background" : "rda-light-background"
      }
    >
      {appBar && <AppNavBar />}
      {children}
    </ContainerBox>
  );
}

const ContainerBox = styled(Box)<BoxProps>({
  display: "flex",
  flex: 1,
  "&.rda-light-background": {
    backgroundColor: "#F2F1EB"
  },
  "&.rda-dark-background": {
    backgroundColor: "#212124"
  }
});
