import { Box, BoxProps, styled } from "@mui/material";

const StyledGridContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "max-content auto",
  columnGap: theme.spacing(1),
  rowGap: theme.spacing(1),
}));

export default StyledGridContainer;
