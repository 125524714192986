import { Typography, TypographyProps, styled } from "@mui/material";

const TradeMarkTypography = styled(Typography)<TypographyProps>({
  fontFamily: "'Chilanka', Roboto, sans-serif",
  fontSize: "0.5rem",
  fontWeight: "700",
  verticalAlign: "super",
  marginLeft: "0.1rem"
}) as typeof Typography;

export default TradeMarkTypography;
