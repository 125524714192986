import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Alert, Box, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import EmptyBoxedMessage from "../components/atoms/EmptyBoxedMessage";
import DraftTable from "../components/organisms/DraftTable";
import TitledPageTemplate from "../components/templates/TitledPageTemplate";
import useDrafts from "../hooks/useDrafts";
import { useAppSelector } from "../redux/hooks";
import DraftController from "../repositories/DraftController";
import InvoiceController from "../repositories/InvoiceController";

export default function DraftsScreen() {
  const navigate = useNavigate();
  const selectedBusiness = useAppSelector((state) => state.business.business);
  const { isLoading, drafts, pagination, reload } = useDrafts();
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(25);
  const [hasPermission, setHasPermission] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");

  React.useEffect(() => {
    InvoiceController.hasPermission()
      .then((permission) => {
        setHasPermission(permission.status === "success");
        setError(permission.message);
      })
      .catch(() => {
        setHasPermission(false);
        setError("Unknown error occured when checking permission.");
      });
  }, [isLoading]);

  React.useEffect(() => {
    reload(rowsPerPage, page); // Get invoices
  }, [selectedBusiness, page, rowsPerPage]);

  const handleDeleteDraft = React.useCallback(
    async (draftId: string) => {
      if (selectedBusiness && selectedBusiness.uuid) {
        await DraftController.delete(selectedBusiness.uuid, draftId);
        await reload(rowsPerPage, page);
      }
    },
    [selectedBusiness, page, rowsPerPage]
  );

  return (
    <TitledPageTemplate
      containerId="rda-screen-drafts"
      title="Drafts"
      isLoading={isLoading}
      isLoadingMessage="Loading drafts..."
    >
      {selectedBusiness && !isLoading && (
        <DraftTable
          drafts={drafts}
          title="Pending Approval"
          selector={
            <Box
              sx={{
                display: "flex",
                flex: 1,
                justifyContent: "flex-end",
                gap: 2
              }}
            >
              {error && (
                <Alert
                  id="permission-error-alert"
                  severity="error"
                  variant="filled"
                  sx={{ p: 0, pl: 3, pr: 3 }}
                >
                  {error}
                </Alert>
              )}
              <Button
                color="secondary"
                variant="contained"
                onClick={() => navigate("/invoices/create")}
                disabled={!hasPermission}
              >
                New Draft
              </Button>
            </Box>
          }
          pagination={pagination}
          onChangePage={(p: number) => setPage(p)}
          onChangeRowsPerPage={(perPage: number) => setRowsPerPage(perPage)}
          onDeleteDraft={handleDeleteDraft}
        />
      )}
      {!isLoading && drafts.length === 0 && (
        <EmptyBoxedMessage
          title="No Draft Found"
          message="You can start by creating a new invoice."
          action={
            <Button
              startIcon={<AddRoundedIcon />}
              aria-label="Add a new invoice"
              onClick={() => navigate("/invoices/create")}
              variant="contained"
              color="secondary"
              sx={{ whiteSpace: "nowrap" }}
            >
              Add Draft
            </Button>
          }
        />
      )}
    </TitledPageTemplate>
  );
}
