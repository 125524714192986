import { Box, BoxProps, Button, Typography, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import GradientBackground from "../layouts/GradientBackground";

export default function LandingScreen() {
  const navigate = useNavigate();

  return (
    <GradientBackground>
      <ContainerBox id="rda-screen-landing">
        <Typography variant="h1">Welcome to RedeuBooks</Typography>
        <Typography sx={{ mt: 1 }}>
          An online invoicing system for small businesses
        </Typography>
        <Button
          onClick={() => navigate("signin")}
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Sign in to create invoice
        </Button>
      </ContainerBox>
    </GradientBackground>
  );
}

const ContainerBox = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  "& h1": {
    fontSize: "2rem",
    color: theme.palette.common.white
  },
  "& p": {
    color: theme.palette.common.white
  }
}));
