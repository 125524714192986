import { SxProps, Theme } from "@mui/material";
import getShortLocalDateFromString from "../helpers/getShortLocalDateFromString";
import { StyledGridContainer } from "../styledMuiComponents";
import { IInvoiceInfo } from "../types/IInvoiceInfo";
import GridRowDescription from "./GridRowDescription";

type InvoiceInfoPrintViewProps = {
  invoiceInfo: IInvoiceInfo;
  sx?: SxProps<Theme>;
};

export default function InvoiceInfoPrintView({
  invoiceInfo,
  sx
}: InvoiceInfoPrintViewProps) {
  const {
    due_date,
    invoice_date,
    number,
    status,
    purchase_order,
    payment_term
  } = invoiceInfo;

  return (
    <StyledGridContainer
      sx={[
        { gridTemplateColumns: "auto min-content", rowGap: 0 },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      <GridRowDescription
        label="Status"
        description={status}
        labelSx={{ display: "block", textAlign: "right" }}
        descriptionSx={{ fontWeight: "700", whiteSpace: "nowrap" }}
      />
      <GridRowDescription
        label="Invoice Number"
        description={number ? number : "Auto-generated"}
        labelSx={{ display: "block", textAlign: "right" }}
        descriptionSx={{ whiteSpace: "nowrap" }}
      />
      <GridRowDescription
        label="Invoice Date"
        description={
          invoice_date ? getShortLocalDateFromString(invoice_date) : "NIL"
        }
        labelSx={{ display: "block", textAlign: "right" }}
        descriptionSx={{ whiteSpace: "nowrap" }}
      />
      <GridRowDescription
        label="Due Date"
        description={due_date ? getShortLocalDateFromString(due_date) : "NIL"}
        labelSx={{ display: "block", textAlign: "right" }}
        descriptionSx={{ whiteSpace: "nowrap" }}
      />
      {purchase_order && (
        <GridRowDescription
          label="Purchase Order"
          description={purchase_order}
          labelSx={{ display: "block", textAlign: "right" }}
          descriptionSx={{ whiteSpace: "nowrap" }}
        />
      )}
      {payment_term && (
        <GridRowDescription
          label="Payment Term"
          description={payment_term}
          labelSx={{ display: "block", textAlign: "right" }}
          descriptionSx={{ whiteSpace: "nowrap" }}
        />
      )}
    </StyledGridContainer>
  );
}
