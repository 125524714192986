import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { IDraft } from "../../../types/IDraft";
import EmptyBoxedMessage from "../../atoms/EmptyBoxedMessage";
import AuditsView from "../../AuditsView";
import InvoiceGridView from "../../molecules/InvoiceGridView";
import TitledPageTemplate from "../TitledPageTemplate";

type DraftPageTemplateProps = {
  title: string;
  isLoading?: boolean;
  isLoadingMessage?: string;
  isEmpty?: boolean;
  isEmptyTitle?: string;
  isEmptyMessage?: string;
  isEmptyAction?: React.ReactNode;
  startHeaderAction?: React.ReactNode;
  endHeaderAction?: React.ReactNode;
  draft?: IDraft | null;
  onClickBack?: () => void;
};

export default function DraftPageTemplate({
  title,
  isLoading,
  isLoadingMessage = "Loading drafts...",
  isEmpty,
  isEmptyTitle = "No draft invoice found",
  isEmptyMessage = "You can start by adding a draft invoice.",
  isEmptyAction,
  startHeaderAction,
  endHeaderAction,
  onClickBack,
  draft
}: DraftPageTemplateProps) {
  return (
    <TitledPageTemplate
      title={title}
      startHeaderAction={startHeaderAction}
      endHeaderAction={endHeaderAction}
      isLoading={isLoading}
      isLoadingMessage={isLoadingMessage.toUpperCase()}
      onClickBack={onClickBack}
    >
      {!isLoading && !isEmpty && draft && (
        <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
          <InvoiceGridView
            isDraft={true}
            billTo={draft.bill_to}
            invoiceId={draft.uuid}
            invoiceInfo={
              draft.invoice ? { ...draft?.invoice, status: "DRAFT" } : undefined
            }
            drafts={draft.drafts}
            subtotal={draft.subtotal}
            grandTotal={draft.grand_total}
            totalTax={draft.total_tax}
            taxes={draft.taxes}
            additionalNote={draft.additional_note}
          />
          {draft?.audits && draft.audits.length > 0 && (
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Audit Trail</Typography>
              <AuditsView audits={draft.audits} />
            </Grid>
          )}
        </Box>
      )}
      {!isLoading && isEmpty && (
        <EmptyBoxedMessage
          title={isEmptyTitle}
          message={isEmptyMessage}
          action={isEmptyAction}
        />
      )}
    </TitledPageTemplate>
  );
}
