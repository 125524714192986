import { createBrowserRouter } from "react-router-dom";
import AddPaymentScreen from "./screens/AddPaymentScreen";
import BusinessCreationScreen from "./screens/BusinessCreationScreen";
import BusinessSingleScreen from "./screens/BusinessSingleScreen";
import BusinessesScreen from "./screens/BusinessesScreen";
import DashboardScreen from "./screens/DashboardScreen";
import DraftSingleScreen from "./screens/DraftSingleScreen";
import DraftsScreen from "./screens/DraftsScreen";
import ErrorPageScreen from "./screens/ErrorPageScreen";
import InvoiceCreationScreen from "./screens/InvoiceCreationScreen";
import InvoicePrintScreen from "./screens/InvoicePrintScreen";
import InvoiceSingleScreen from "./screens/InvoiceSingleScreen";
import InvoicesScreen from "./screens/InvoicesScreen";
import LandingScreen from "./screens/LandingScreen";
import RootScreen from "./screens/RootScreen";
import SalesReceivableScreen from "./screens/SalesReceivableScreen";
import SignInScreen from "./screens/SignInScreen";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <RootScreen />,
    errorElement: <ErrorPageScreen />,
    children: [
      {
        path: "/",
        element: <LandingScreen />
      },
      {
        path: "dashboard",
        element: <DashboardScreen />
      },
      {
        path: "invoices",
        element: <InvoicesScreen />
      },
      {
        path: "invoices/create/:invoiceId?",
        element: <InvoiceCreationScreen />
      },
      {
        path: "invoices/:invoiceId/view",
        element: <InvoiceSingleScreen />
      },
      {
        path: "invoices/:invoiceId/payment",
        element: <AddPaymentScreen />
      },
      {
        path: "drafts/:invoiceId/view",
        element: <DraftSingleScreen />
      },
      {
        path: "drafts",
        element: <DraftsScreen />
      },
      {
        path: "journals",
        element: <SalesReceivableScreen />
      },
      {
        path: "signin",
        element: <SignInScreen />
      },
      {
        path: "businesses",
        element: <BusinessesScreen />
      },
      {
        path: "businesses/create",
        element: <BusinessCreationScreen />
      },
      {
        path: "businesses/:businessId",
        element: <BusinessSingleScreen />
      },
      {
        path: "businesses/:businessId/edit",
        element: <BusinessCreationScreen />
      }
    ]
  },
  {
    // Standalone page, Doesn't inherit RootScreen
    path: "/invoices/:invoiceId/print/:invoiceNumber",
    element: <InvoicePrintScreen />
  }
]);
