import { Typography, TypographyProps, styled } from "@mui/material";

const PageTitleTypography = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    fontSize: "1.25rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(1)
  })
);

export default PageTitleTypography;
