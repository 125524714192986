import React from "react";
import { useAppSelector } from "../redux/hooks";
import InvoiceController from "../repositories/InvoiceController";
import { IInvoice } from "../types/IInvoice";

type UseSingleInvoiceResponse = {
  isLoading: boolean;
  invoice: IInvoice | null;
  reload: (invoiceId: string) => Promise<void>;
};

export default function useSingleInvoice(): UseSingleInvoiceResponse {
  const selectedBusiness = useAppSelector((state) => state.business.business);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [invoice, setInvoice] = React.useState<IInvoice | null>(null);

  const reload = React.useCallback(
    async (invoiceId: string) => {
      setIsLoading(true);
      setInvoice(null);
      if (selectedBusiness?.uuid) {
        const response = await InvoiceController.get(
          selectedBusiness.uuid,
          invoiceId
        );
        setInvoice(response);
      }
      setIsLoading(false);
    },
    [selectedBusiness]
  );

  const memodValue: UseSingleInvoiceResponse = React.useMemo(
    () => ({
      isLoading,
      invoice,
      reload
    }),
    [isLoading, invoice, reload]
  );

  return memodValue;
}
