import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Alert, Box, Button } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";
import EmptyBoxedMessage from "../components/atoms/EmptyBoxedMessage";
import JournalTable from "../components/organisms/JournalTable";
import TitledPageTemplate from "../components/templates/TitledPageTemplate";
import useJournals from "../hooks/useJournals";
import { useAppSelector } from "../redux/hooks";
import { IJournalRequestOptions } from "../types/IJournalRequestOptions";

export default function SalesReceivableScreen() {
  const navigate = useNavigate();
  const selectedBusiness = useAppSelector((state) => state.business.business);
  const { isLoading, journals, pagination, reload, error } = useJournals();
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(25);
  const [startDate, setStartDate] = React.useState<Dayjs | null>(
    dayjs().startOf("year")
  );
  const [endDate, setEndDate] = React.useState<Dayjs | null>(dayjs());

  React.useEffect(() => {
    const options: IJournalRequestOptions = {};
    options.limit = rowsPerPage;
    options.page = page;

    if (startDate && endDate) {
      options.start_date = startDate.toISOString();
      options.end_date = endDate.toISOString();
    }

    reload(options); // Get invoices
  }, [selectedBusiness, page, rowsPerPage, startDate, endDate]);

  return (
    <TitledPageTemplate
      containerId="rda-screen-salesreceivable"
      title="Journals"
      isLoading={isLoading}
      isLoadingMessage="Loading journals..."
    >
      {error && (
        <Alert severity="error" variant="filled" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      {selectedBusiness && !isLoading && (
        <JournalTable
          journals={journals}
          title="Sales & Receivable"
          pagination={pagination}
          onChangePage={(p: number) => setPage(p)}
          onChangeRowsPerPage={(perPage: number) => setRowsPerPage(perPage)}
          selector={
            <Box display="flex" gap={2}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue, context) => {
                  if (!context.validationError) {
                    setStartDate(newValue);
                  }
                }}
                closeOnSelect
                maxDate={dayjs().add(1, "day")}
                slotProps={{ textField: { size: "small" } }}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue, context) => {
                  if (!context.validationError) {
                    setEndDate(newValue);
                  }
                }}
                closeOnSelect
                maxDate={dayjs().add(1, "day")}
                slotProps={{ textField: { size: "small" } }}
              />
            </Box>
          }
        />
      )}
      {!isLoading && journals.length === 0 && (
        <EmptyBoxedMessage
          title="No Journal Found"
          message="You can start by creating a new invoice."
          action={
            <Button
              startIcon={<AddRoundedIcon />}
              aria-label="Add a new invoice"
              onClick={() => navigate("/invoices/create")}
              variant="contained"
              color="secondary"
              sx={{ whiteSpace: "nowrap" }}
            >
              Add Invoice
            </Button>
          }
        />
      )}
    </TitledPageTemplate>
  );
}
