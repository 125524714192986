import { TableContainer, TableContainerProps, styled } from "@mui/material";

const StyledTableContainer = styled(TableContainer)<TableContainerProps>(
  ({ theme }) => ({
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    "& .rdr-invoice-table-row": {
      "&:nth-of-type(odd)": {
        backgroundColor: `${theme.palette.action.hover}`,
      },
    },
  })
);

export default StyledTableContainer;
