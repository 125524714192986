import { Box, SxProps, Theme, Typography } from "@mui/material";
import {
  GradientCircularProgress,
  LoadingContainer
} from "../../../styledMuiComponents";

type LoadingBoxProps = {
  message?: string;
  sx?: SxProps<Theme>;
};

export default function LoadingBox({ message, sx = [] }: LoadingBoxProps) {
  return (
    <LoadingContainer sx={sx}>
      <GradientCircularProgress />
      {message && (
        <Box sx={{ mt: 2 }}>
          <Typography sx={{ fontSize: 11 }}>{message}</Typography>
        </Box>
      )}
    </LoadingContainer>
  );
}
