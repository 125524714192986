import { Button, ButtonProps, styled } from "@mui/material";

const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  lineHeight: "initial", // To remove the weird text alignment issue
  padding: theme.spacing(0.8),
  paddingRight: theme.spacing(1.5),
  paddingLeft: theme.spacing(1.5),
}));

export default StyledButton;
