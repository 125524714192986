import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import {
  Button,
  ButtonGroup,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableRowProps,
  TextField,
  TextFieldProps,
  Tooltip,
  styled,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React from "react";
import getAmountInCurrency from "../helpers/getAmountInCurrency";
import {
  StyledResponsiveTable,
  StyledResponsiveTableRowTitle
} from "../styledMuiComponents";
import { IJournal } from "../types/IJournal";
import { ITax } from "../types/ITax";

type JournalsFormProps = {
  journals: IJournal[];
  currency?: string;
  tax: ITax | null;
  onAddJournal: (i: IJournal[]) => void;
  onToggleForm: (o: boolean) => void;
};

export default function JournalsForm({
  journals,
  currency,
  tax,
  onAddJournal,
  onToggleForm
}: JournalsFormProps) {
  const theme = useTheme();
  const isLargerScreens = useMediaQuery(theme.breakpoints.up("md"));

  const [title, setTitle] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [quantity, setQuantity] = React.useState<string>("");
  const [unit_price, setUnitPrice] = React.useState<string>("");
  const [showForm, setShowForm] = React.useState<boolean>(false);

  const addItem = React.useCallback(() => {
    if (isNaN(Number(quantity)) || isNaN(Number(unit_price)) || title === "") {
      setShowForm(false);
      onToggleForm(false);
      return null; // Do nothing until user correct the mistake
    }

    const roundedQuantity = parseFloat(quantity).toFixed(2);
    const roundedUnitPrice = parseFloat(unit_price).toFixed(2);
    const amount = Number(roundedQuantity) * Number(roundedUnitPrice);

    const newItems = [...journals];
    newItems.push({
      uuid: "",
      parent: "",
      amount: amount.toFixed(2).toString(),
      currency: currency ? currency : "",
      credit: "",
      debit: "",
      datetime: "",
      created_at: "",
      updated_at: "",
      document: {
        name: title,
        description: description,
        quantity: roundedQuantity,
        unit_price: roundedUnitPrice
      }
    });

    onAddJournal(newItems);
    // Reset to blank
    setTitle("");
    setDescription("");
    setQuantity("");
    setUnitPrice("");
    setShowForm(false);
    onToggleForm(false);
  }, [journals, onAddJournal, quantity, unit_price, title, description]);

  const removeItem = React.useCallback(
    (index: number) => {
      const _rows = [...journals];
      _rows.splice(index, 1);
      onAddJournal(_rows);
    },
    [journals, onAddJournal]
  );

  const subAmountBeforeTax = React.useMemo(() => {
    return journals.reduce(
      (accumulator, currentValue) => accumulator + Number(currentValue.amount),
      0
    );
  }, [journals]);

  const taxAmount = React.useMemo(() => {
    if (tax && subAmountBeforeTax) {
      return subAmountBeforeTax * parseFloat(tax.rate);
    }
    return 0;
  }, [tax, subAmountBeforeTax]);

  const grandAmount = React.useMemo(() => {
    return subAmountBeforeTax + taxAmount;
  }, [subAmountBeforeTax, taxAmount]);

  const handleEditJournal = React.useCallback(
    (index: number, item: IJournal) => {
      setDescription(item.document.description);
      setTitle(item.document.name);
      setQuantity(item.document.quantity);
      setUnitPrice(item.document.unit_price);
      setShowForm(true);
      removeItem(index);
    },
    [removeItem]
  );

  return (
    <StyledResponsiveTable
      aria-label="Journals table form"
      className={isLargerScreens ? "large-screen" : "small-screen"}
    >
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: "50px" }}></TableCell>
          <TableCell>Item</TableCell>
          <TableCell>Description</TableCell>
          <TableCell sx={{ width: "200px" }}>
            Quantity
            <Tooltip title="Can support up to 2 decimal places" arrow>
              <HelpRoundedIcon
                fontSize="small"
                color="secondary"
                sx={{ verticalAlign: "text-bottom", ml: 0.5 }}
              />
            </Tooltip>
          </TableCell>
          <TableCell sx={{ width: "200px" }}>
            Unit Price
            <Tooltip title="Only supports up to 2 decimal places" arrow>
              <HelpRoundedIcon
                fontSize="small"
                color="secondary"
                sx={{ verticalAlign: "text-bottom", ml: 0.5 }}
              />
            </Tooltip>
          </TableCell>
          <TableCell sx={{ width: "200px" }}>
            {journals?.length > 0 && "Subtotal"}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {journals &&
          journals.map((item, index) => (
            <TableRow key={index}>
              {!isLargerScreens && (
                <StyledResponsiveTableRowTitle title="Action" />
              )}
              <TableCell>
                <ButtonGroup size="small">
                  <IconButton
                    onClick={() => removeItem(index)}
                    color="primary"
                    size="small"
                  >
                    <DeleteRoundedIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleEditJournal(index, item)}
                    color="primary"
                    size="small"
                  >
                    <EditRoundedIcon />
                  </IconButton>
                </ButtonGroup>
              </TableCell>
              {!isLargerScreens && (
                <StyledResponsiveTableRowTitle title="Item" />
              )}
              <TableCell>{item.document.name}</TableCell>
              {!isLargerScreens && (
                <StyledResponsiveTableRowTitle title="Description" />
              )}
              <TableCell>{item.document.description}</TableCell>
              {!isLargerScreens && (
                <StyledResponsiveTableRowTitle title="Quantity" />
              )}
              <TableCell>{item.document.quantity}</TableCell>
              {!isLargerScreens && (
                <StyledResponsiveTableRowTitle title="Unit Price" />
              )}
              <TableCell>{item.document.unit_price}</TableCell>
              {!isLargerScreens && (
                <StyledResponsiveTableRowTitle title="Amount" />
              )}
              <TableCell>{item.amount}</TableCell>
            </TableRow>
          ))}
        {journals && journals.length > 0 && tax && (
          <TableRow>
            <TableCell variant="head" colSpan={5} align="right">
              Sub Total
            </TableCell>
            <TableCell variant="head">
              {currency
                ? getAmountInCurrency(currency, subAmountBeforeTax)
                : subAmountBeforeTax.toFixed(2)}
            </TableCell>
          </TableRow>
        )}
        {tax && (
          <TableRow>
            <TableCell variant="head" colSpan={5} align="right">
              Total Tax ({tax.name})
            </TableCell>
            <TableCell variant="head">
              {currency
                ? getAmountInCurrency(currency, taxAmount)
                : taxAmount.toFixed(2)}
            </TableCell>
          </TableRow>
        )}
        {journals && journals.length > 0 && (
          <TableRow>
            <TableCell variant="head" colSpan={5} align="right">
              Grand Total
            </TableCell>
            <TableCell variant="head">
              {currency
                ? getAmountInCurrency(currency, grandAmount)
                : grandAmount.toFixed(2)}
            </TableCell>
          </TableRow>
        )}
        {showForm ? (
          <StyledFormRow className="single-responsive-form">
            <TableCell></TableCell>
            <TableCell>
              <StyledTextField
                fullWidth={true}
                autoFocus
                id="inputTitle"
                label="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </TableCell>
            <TableCell>
              <StyledTextField
                fullWidth={true}
                id="inputDescription"
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </TableCell>
            <TableCell>
              <StyledTextField
                fullWidth={true}
                id="inputQuantity"
                label="Quantity"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                error={isNaN(Number(quantity))}
                helperText={isNaN(Number(quantity)) && "Numbers only."}
              />
            </TableCell>
            <TableCell>
              <StyledTextField
                fullWidth={true}
                id="inputUnitPrice"
                label="Unit Price"
                value={unit_price}
                onChange={(e) => setUnitPrice(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    addItem();
                  }
                }}
                error={isNaN(Number(unit_price))}
                helperText={isNaN(Number(unit_price)) && "Numbers only."}
              />
            </TableCell>
            <TableCell align="right">
              <ButtonGroup
                variant="contained"
                aria-label="Journal Form Button Group"
              >
                <Button
                  startIcon={<AddRoundedIcon />}
                  onClick={() => addItem()}
                >
                  Add
                </Button>
                <Button
                  onClick={() => {
                    setShowForm(false);
                    onToggleForm(false);
                  }}
                  color="secondary"
                >
                  <CloseRoundedIcon />
                </Button>
              </ButtonGroup>
            </TableCell>
          </StyledFormRow>
        ) : (
          <StyledFormRow>
            <TableCell colSpan={6} align="center">
              <Button
                variant="contained"
                endIcon={<AddRoundedIcon />}
                onClick={() => {
                  setShowForm(true);
                  onToggleForm(true);
                }}
                color="secondary"
              >
                Add new {journals.length > 0 ? "line" : "item"}
              </Button>
            </TableCell>
          </StyledFormRow>
        )}
      </TableBody>
    </StyledResponsiveTable>
  );
}

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  backgroundColor: theme.palette.common.white
}));

const StyledFormRow = styled(TableRow)<TableRowProps>(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  borderBottomLeftRadius: theme.spacing(2),
  borderBottomRightRadius: theme.spacing(2),
  "& td": {
    border: 0
  },
  "& td:first-of-type": {
    borderBottomLeftRadius: theme.spacing(2)
  },
  "& td:last-of-type": {
    borderBottomRightRadius: theme.spacing(2)
  }
}));
