import { Paper, SxProps, TableContainer, Theme } from "@mui/material";
import React from "react";
import getAmountInCurrency from "../helpers/getAmountInCurrency";
import getLocalDateFromString from "../helpers/getLocalDateFromString";
import getShortLocalDateFromString from "../helpers/getShortLocalDateFromString";
import { IPayment } from "../types/IPayment";
import ResponsiveTable from "./atoms/ResponsiveTable";
import IResponsiveTableHeader from "./atoms/ResponsiveTable/types/IResponsiveTableHeader";
import IResponsiveTableRow from "./atoms/ResponsiveTable/types/IResponsiveTableRow";

type PaymentsViewProps = {
  payments: IPayment[];
  sx?: SxProps<Theme>;
};

const PAYMENTS_VIEW_HEADERS: IResponsiveTableHeader[] = [
  {
    index: "payments-header-amount",
    label: "Amount"
  },
  {
    index: "payments-header-payment-date",
    label: "Payment Date"
  },
  {
    index: "payments-header-method",
    label: "Method"
  },
  {
    index: "payments-header-notes",
    label: "Notes"
  },
  {
    index: "payments-header-added-on",
    label: "Added on"
  }
];

const PaymentsView = ({ payments, sx = [] }: PaymentsViewProps) => {
  const paymentRows: IResponsiveTableRow[] = React.useMemo(() => {
    const _rows: IResponsiveTableRow[] = [];
    payments.map((p, i) => {
      _rows.push({
        uuid: p.uuid,
        columns: [
          {
            headerIdx: "payments-header-amount",
            uuid: `payments-header-amount-${i}`,
            label: getAmountInCurrency(p.currency, p.amount)
          },
          {
            headerIdx: "payments-header-payment-date",
            uuid: `payments-header-payment-date-${i}`,
            label: getShortLocalDateFromString(p.datetime)
          },
          {
            headerIdx: "payments-header-method",
            uuid: `payments-header-method-${i}`,
            label: p.document.method
          },
          {
            headerIdx: "payments-header-notes",
            uuid: `payments-header-notes-${i}`,
            label: p.document.note
          },
          {
            headerIdx: "payments-header-added-on",
            uuid: `payments-header-added-on-${i}`,
            label: getLocalDateFromString(p.created_at)
          }
        ]
      });
    });

    return _rows;
  }, [payments]);

  return (
    <TableContainer
      id="rda-payments-table"
      component={Paper}
      sx={sx}
      variant="outlined"
    >
      <ResponsiveTable headers={PAYMENTS_VIEW_HEADERS} rows={paymentRows} />
    </TableContainer>
  );
};

export default PaymentsView;
