import dayjs from "dayjs";

const defaultOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

export default function getShortLocalDateFromString(
  stringDate: string,
  options: Intl.DateTimeFormatOptions = defaultOptions
): string {
  return new Intl.DateTimeFormat("en-GB", options).format(
    dayjs(stringDate).valueOf()
  );
}
