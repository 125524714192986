import { Autocomplete, SxProps, TextField, Theme } from "@mui/material";
import React from "react";
import currenciesData from "../config/currencies.json";

const currencies = currenciesData.reduce<string[]>((acc, cur) => {
  acc.push(cur.cc);
  return acc;
}, []);

type CurrencySelectorProps = {
  selectedCode: string | null;
  onChange: (code: string | null) => void;
  sx?: SxProps<Theme>;
  size?: "small" | "medium";
  fullWidth?: boolean;
  disabled?: boolean;
  helperText?: string;
};

export default function CurrencySelector({
  selectedCode,
  onChange,
  sx = [],
  size = "small",
  fullWidth = false,
  disabled = false,
  helperText,
}: CurrencySelectorProps) {
  return (
    <Autocomplete
      disablePortal
      id="rdr-currency-selector-form"
      value={selectedCode}
      onChange={(e, value) => onChange(value)}
      options={currencies}
      getOptionLabel={(option) => option}
      fullWidth={fullWidth}
      size={size}
      sx={sx}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Currency"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
            ),
          }}
          helperText={helperText}
        />
      )}
    />
  );
}
