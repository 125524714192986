import { Typography, styled } from "@mui/material";
import React from "react";

export default function PaperFormFooter() {
  return (
    <StyledCopyright>&copy; Copyright since 2021, Redooor LLP</StyledCopyright>
  );
}

const StyledCopyright = styled(Typography)({
  width: "100%",
  textAlign: "center",
  fontSize: "0.6rem",
  fontWeight: "300",
  paddingTop: "1rem",
}) as typeof Typography;
