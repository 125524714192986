import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Button, Paper, Typography } from "@mui/material";
import React, { Suspense } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EmptyBoxedMessage from "../components/atoms/EmptyBoxedMessage";
import ListItem from "../components/atoms/ListItem";
import LoadingBox from "../components/atoms/LoadingBox";
import InvoiceTemplateViewer from "../components/InvoiceTemplateViewer";
import TitledPageTemplate from "../components/templates/TitledPageTemplate";
import useBusiness from "../hooks/useBusiness";

const CompanyView = React.lazy(
  () => import("../components/molecules/CompanyView")
);

export default function BusinessSingleScreen() {
  const { businessId } = useParams();
  const navigate = useNavigate();
  const { isLoading, business, reload } = useBusiness();

  React.useEffect(() => {
    reload(businessId);
  }, [businessId, reload]);

  return (
    <TitledPageTemplate
      containerId="rda-screen-business-viewer"
      title="Business"
      isLoading={isLoading}
      isLoadingMessage="Loading business..."
      onClickBack={() => navigate("/businesses")}
      endHeaderAction={
        businessId && (
          <Button
            startIcon={<EditRoundedIcon />}
            aria-label="Edit"
            onClick={() => navigate(`/businesses/${businessId}/edit`)}
            variant="contained"
            color="secondary"
          >
            Edit
          </Button>
        )
      }
    >
      {!isLoading && business && (
        <Paper
          variant="outlined"
          sx={{ p: 3, display: "flex", flexDirection: "column", gap: 1 }}
        >
          <Paper variant="outlined" sx={{ borderStyle: "dashed", p: 3 }}>
            {business.logo?.startsWith("data:image") ? (
              <img
                src={business.logo}
                alt="Business Logo"
                height="auto"
                width="auto"
                style={{ maxWidth: "200px" }}
              />
            ) : (
              <Typography>No logo</Typography>
            )}
          </Paper>
          <Paper variant="outlined" sx={{ borderStyle: "dashed", p: 3 }}>
            {business.company ? (
              <Suspense fallback={<LoadingBox message="Loading..." />}>
                <CompanyView company={business.company}>
                  <ListItem
                    label="Abbreviation"
                    description={business.abbreviation || "Not specified"}
                  />
                  <ListItem
                    label="Business Currency"
                    description={
                      business.currency || "Not specified (default SGD)"
                    }
                  />
                </CompanyView>
              </Suspense>
            ) : (
              <Typography>No Company Detail</Typography>
            )}
          </Paper>
          {business.template && (
            <Paper variant="outlined" sx={{ borderStyle: "dashed", p: 3 }}>
              <InvoiceTemplateViewer template={business.template} />
            </Paper>
          )}
        </Paper>
      )}
      {!isLoading && !business && (
        <EmptyBoxedMessage
          title="No business found"
          message="There might be something wrong with the Business ID provided."
        />
      )}
    </TitledPageTemplate>
  );
}
