import { Paper, SxProps, TableContainer, Theme } from "@mui/material";
import React from "react";
import getLocalDateFromString from "../helpers/getLocalDateFromString";
import { IAudit } from "../types/IAudit";
import ResponsiveTable from "./atoms/ResponsiveTable";
import IResponsiveTableHeader from "./atoms/ResponsiveTable/types/IResponsiveTableHeader";
import IResponsiveTableRow from "./atoms/ResponsiveTable/types/IResponsiveTableRow";

type AuditsViewProps = {
  audits: IAudit[];
  sx?: SxProps<Theme>;
};

const AUDITS_VIEW_HEADERS: IResponsiveTableHeader[] = [
  {
    index: "audits-header-comment",
    label: "Comment"
  },
  {
    index: "audits-header-display-name",
    label: "Name"
  },
  {
    index: "audits-header-email",
    label: "Email"
  },
  {
    index: "audits-header-created-at",
    label: "Created At"
  }
];

const AuditsView = ({ audits, sx = [] }: AuditsViewProps) => {
  const auditRows: IResponsiveTableRow[] = React.useMemo(() => {
    const _rows: IResponsiveTableRow[] = [];

    audits.map((audit, i) => {
      _rows.push({
        uuid: audit.uuid,
        columns: [
          {
            headerIdx: "audits-header-comment",
            uuid: `audits-header-comment-${i}`,
            label: audit.comment
          },
          {
            headerIdx: "audits-header-display-name",
            uuid: `audits-header-display-name-${i}`,
            label: audit.displayName || "Not recorded"
          },
          {
            headerIdx: "audits-header-email",
            uuid: `audits-header-email-${i}`,
            label: audit.email || "Not recorded"
          },
          {
            headerIdx: "audits-header-created-at",
            uuid: `audits-header-created-at-${i}`,
            label: getLocalDateFromString(audit.created_at)
          }
        ]
      });
    });

    return _rows;
  }, [audits]);

  return (
    <TableContainer
      component={Paper}
      id="rda-audits-table"
      sx={sx}
      variant="outlined"
    >
      <ResponsiveTable headers={AUDITS_VIEW_HEADERS} rows={auditRows} />
    </TableContainer>
  );
};

export default AuditsView;
