import firebase from "firebase/compat/app";
import * as React from "react";
import app from "../config/app";

type UseFirebaseResponse = {
  initiated: boolean;
};

export default function useFirebase(): UseFirebaseResponse {
  const [initiated, setInitiated] = React.useState<boolean>(false);

  React.useEffect(() => {
    firebase.initializeApp(app.firebase_config);
    setInitiated(true);

    return () => {
      setInitiated(false);
    };
  }, []);

  return React.useMemo(
    (): UseFirebaseResponse => ({
      initiated,
    }),
    [initiated]
  );
}
