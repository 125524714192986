import {
  Box,
  BoxProps,
  SxProps,
  Theme,
  Typography,
  TypographyProps,
  styled,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React from "react";

type HeaderContainerWithFilterProps = {
  title?: string;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
};

export default function HeaderContainerWithFilter({
  title,
  children
}: HeaderContainerWithFilterProps) {
  const theme = useTheme();
  const isLargerScreens = useMediaQuery(theme.breakpoints.up("md"));
  const responsiveStyle: SxProps<Theme> = {
    flexDirection: isLargerScreens ? "row" : "column",
    alignItems: isLargerScreens ? "center" : "stretch"
  };

  return (
    <StyledContainer sx={responsiveStyle}>
      {title && <TitleTypography>{title}</TitleTypography>}
      {children}
    </StyledContainer>
  );
}

const StyledContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  columnGap: theme.spacing(2),
  rowGap: theme.spacing(1),
  marginBottom: theme.spacing(1)
}));

const TitleTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: 24,
  color: theme.palette.text.primary
}));
