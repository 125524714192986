import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IBusiness } from "../types/IBusiness";

interface BusinessState {
  business: IBusiness | null;
  isLoading: boolean;
}

const initialBusinessState: BusinessState = {
  business: null,
  isLoading: true,
};

export const businessSlice = createSlice({
  name: "business",
  initialState: initialBusinessState,
  reducers: {
    unload: (state) => {
      state.isLoading = true;
      state.business = null;
    },
    update: (state, action: PayloadAction<IBusiness>) => {
      state.isLoading = false;
      state.business = action.payload;
    },
  },
});

export const { unload, update } = businessSlice.actions;

export default businessSlice.reducer;
