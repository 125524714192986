import {
  Alert,
  Box,
  BoxProps,
  Button,
  Grid,
  TextField,
  styled
} from "@mui/material";
import React from "react";
import { IBillto } from "../types/IBillto";

type BilltoFormProps = {
  billto: IBillto | null;
  setBillto: (bt: IBillto | null) => void;
  onDismiss: () => void;
};

export default function BilltoForm({
  billto,
  setBillto,
  onDismiss
}: BilltoFormProps) {
  const [error, setError] = React.useState<string>("");
  const [attention_to, setAttentionTo] = React.useState("");
  const [name, setName] = React.useState("");
  const [uen, setUen] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [email, setEmail] = React.useState("");
  // Address
  const [line1, setLine1] = React.useState("");
  const [line2, setLine2] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [zipcode, setZipcode] = React.useState("");

  React.useEffect(() => {
    if (billto) {
      if (billto.attention_to) {
        setAttentionTo(billto.attention_to);
      }
      if (billto.company) {
        setName(billto.company.name);
        setUen(billto.company.uen);
        setPhone(billto.company.phone);
        setMobile(billto.company.mobile);
        setWebsite(billto.company.website);
        setEmail(billto.company.email);
        // Address
        if (billto.company.address) {
          setLine1(billto.company.address.line1);
          setLine2(billto.company.address.line2);
          setCity(billto.company.address.city);
          setState(billto.company.address.state);
          setCountry(billto.company.address.country);
          setZipcode(billto.company.address.zipcode);
        }
      }
    }
  }, [billto]);

  const handleComplete = React.useCallback(() => {
    if (!name || !attention_to) {
      setError("'Name' and 'Attention-To' are required fields.");
      return;
    }

    setBillto({
      attention_to: attention_to,
      company: {
        name: name,
        uen: uen,
        address: {
          line1: line1,
          line2: line2,
          city: city,
          state: state,
          country: country,
          zipcode: zipcode
        },
        phone: phone,
        mobile: mobile,
        website: website,
        email: email
      }
    });
    onDismiss();
  }, [
    attention_to,
    name,
    uen,
    phone,
    mobile,
    website,
    email,
    line1,
    line2,
    city,
    state,
    country,
    zipcode,
    onDismiss
  ]);

  const handleCancel = React.useCallback(() => {
    setBillto(null);
    onDismiss();
  }, [onDismiss]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          size="small"
          id="inputName"
          label="Company Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          fullWidth
          size="small"
          id="inputAttentionTo"
          label="Attention To"
          value={attention_to}
          onChange={(e) => setAttentionTo(e.target.value)}
          required
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          fullWidth
          size="small"
          id="inputUen"
          label="UEN"
          value={uen}
          onChange={(e) => setUen(e.target.value)}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          fullWidth
          size="small"
          id="inputLine1"
          label="Address Line 1"
          value={line1}
          onChange={(e) => setLine1(e.target.value)}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          fullWidth
          size="small"
          id="inputLine2"
          label="Address Line 2"
          value={line2}
          onChange={(e) => setLine2(e.target.value)}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          fullWidth
          size="small"
          id="inputCity"
          label="City"
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          fullWidth
          size="small"
          id="inputState"
          label="State"
          value={state}
          onChange={(e) => setState(e.target.value)}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          fullWidth
          size="small"
          id="inputCountry"
          label="Country"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          fullWidth
          size="small"
          id="inputZipcode"
          label="Zip Code"
          value={zipcode}
          onChange={(e) => setZipcode(e.target.value)}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          fullWidth
          size="small"
          id="inputPhone"
          label="Phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          fullWidth
          size="small"
          id="inputMobile"
          label="Mobile"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          fullWidth
          size="small"
          id="inputWebsite"
          label="Website"
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          fullWidth
          size="small"
          id="inputEmail"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <ButtonsContainerBox>
          {error && (
            <Alert severity="error" variant="filled">
              {error}
            </Alert>
          )}
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => handleCancel()}
            sx={{ ml: 1 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleComplete()}
            sx={{ ml: 1 }}
          >
            Done
          </Button>
        </ButtonsContainerBox>
      </Grid>
    </Grid>
  );
}

const ButtonsContainerBox = styled(Box)<BoxProps>({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end"
});
